<template>
  <div id="content" data-elbglobals="page_type:cart">
    <AppBanner />
    <UspBar />
    <TheHeader />
    <TheCartTemplate @setupCheckout="(val) => (this.canCheckout = val)" />
    <LazyComponent
      id="recent-items"
      v-if="customerProfile.customer_id"
      :delay="500"
    >
      <TheRecentlyViewedItems class="pt-5" />
    </LazyComponent>
    <LazyComponent id="footer">
      <TheFooter />
    </LazyComponent>
    <MultiTools id="fixed-tools" v-if="customerProfile.is_admin">
      <Tool :type="adminTool.TRANSLATE_MODE" />
      <Tool :type="adminTool.HIGHLIGHT_CMS_ITEMS" />
      <Tool :type="adminTool.TOGGLE_DATALAYER_LOGGING" />
      <Tool :type="adminTool.TOGGLE_ELBWALKER_LOGGING" />
      <Tool :type="adminTool.TOGGLE_HIGH_CONTRAST" />
      <Tool :type="adminTool.TEXT_INCREASE" />
      <Tool :type="adminTool.TEXT_PERCENTAGE" />
      <Tool :type="adminTool.TEXT_DECREASE" />
    </MultiTools>
    <TheCartMobileCTA v-if="showMobileCTA" />
  </div>
</template>

<script>
import UspBar from '@/components/basic/atoms/UspBar.vue'
import TheCartTemplate from '@/components/cart/templates/TheCartTemplate.vue'
import { TYPES } from '@/constants/AdminTool'
import { NO_INDEX_FOLLOW } from '@/services/helpers/MetaHelper.js'
import { useCustomerStore } from '@/stores/customer'
import { mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'
const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const TheHeader = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheHeader.vue')
)
const AppBanner = defineAsyncComponent(
  () => import('@/components/basic/molecules/AppBanner.vue')
)
const TheCartMobileCTA = defineAsyncComponent(
  () => import('@/components/cart/molecules/TheCartMobileCTA.vue')
)
const MultiTools = defineAsyncComponent(
  () => import('@/components/basic/molecules/MultiTools.vue')
)
const TheRecentlyViewedItems = defineAsyncComponent(
  () => import('@/components/basic/molecules/TheRecentlyViewedItems.vue')
)
const TheFooter = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheFooter.vue')
)
const Tool = defineAsyncComponent(
  () => import('@/components/basic/atoms/Tool.vue')
)

export default {
  head() {
    return {
      title: this.title,
      meta: [
        {
          name: 'robots',
          content: NO_INDEX_FOLLOW
        },
        {
          name: 'description',
          content: this.translate('fallback_meta_description')
        }
      ]
    }
  },
  components: {
    LazyComponent,
    TheCartTemplate,
    TheCartMobileCTA,
    MultiTools,
    TheHeader,
    TheRecentlyViewedItems,
    TheFooter,
    AppBanner,
    Tool,
    UspBar
  },
  data() {
    return {
      adminTool: TYPES,
      canCheckout: false
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerProfile']),
    title() {
      return this.translate('new_cart_page_cart')
    },
    showMobileCTA() {
      return this.canCheckout && this.$vuetify.display.mdAndDown
    }
  }
}
</script>

<style scoped>
.sidebar > div {
  top: 0;
}
</style>
