import { defineStore } from 'pinia'

export const useRecommendationStore = defineStore('recommendations', {
  state() {
    return {
      ready: false,
      inProgress: false,
      data: {
        successor: [],
        bundle: [],
        simple: [],
        follow_up: []
      }
    }
  },
  getters: {
    getByType:
      ({ data }) =>
      (type) =>
        _getByType(type, data),
    hasPersonalizeRecommendations: ({
      data: { successor, bundle, simple, follow_up: fu }
    }) => successor.length + bundle.length + simple.length + fu.length > 0,
    hasMinimum:
      ({ data }) =>
      (type, min) =>
        _getByType(type, data).length >= min,
    updating: ({ inProgress }) => inProgress,
    fetched: ({ inProgress }) => inProgress
  },
  actions: {
    async fetch() {
      if (!(await this.preCheck())) {
        this.ready = true
        return
      }

      const { ApiController } = await _getModules()

      this.inProgress = true

      try {
        this.data = _prepare(await ApiController.fetchRecommendations())
      } catch (e) {
        console.error(e)
      }

      this.ready = true
      this.inProgress = false
    },
    async preCheck() {
      if (this.ready || this.inProgress) return false

      const { customerStore } = await _getModules()
      await customerStore.waitUntilPending()

      return customerStore.customerSoftLoggedIn
    },
    async waitUntilReady() {
      while (!this.ready) await _wait()
      return Promise.resolve()
    }
  }
})

const _wait = async () =>
  new Promise((resolve) => {
    setTimeout(resolve, 100)
  })

const _getByType = (type, data) => {
  if (!type)
    return [
      ...data.successor,
      ...data.bundle,
      ...data.simple,
      ...data.follow_up
    ]

  return data[type] ?? []
}

const _prepare = ({
  successor = [],
  bundle = [],
  simple = [],
  follow_up: fu = [],
  single_campaign_id: sCId,
  bundle_campaign_id: bCId
}) => ({
  successor: _mapper(successor, sCId),
  bundle: _mapper(bundle, bCId),
  simple: _mapper(simple, sCId),
  follow_up: _mapper(fu, sCId)
})

const _mapper = (ref, cId) =>
  ref?.map(({ product_id: pId }) => ({ product_id: pId, campaign_id: cId }))

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ default: ApiController }, { useCustomerStore }] =
      await Promise.all([
        await import('@/services/ApiController'),
        await import('@/stores/customer')
      ])
    cache = { ApiController, customerStore: useCustomerStore() }
    return cache
  }
})()
