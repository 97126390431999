<script setup>
import { useCss } from '@/composables/useCss.js'
import { useJs } from '@/composables/useJs.js'
import { useTracking } from '@/composables/useTracking.js'
import { useCmsPagesStore } from '@/stores/cmsPages'
import { useCustomerStore } from '@/stores/customer'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, onMounted, reactive, ref } from 'vue'

const Processor = defineAsyncComponent(
  () => import('@/components/cms/Processor.vue')
)
const LoadingOverlay = defineAsyncComponent(
  () => import('@/components/basic/atoms/LoadingOverlay.vue')
)
const MultiTools = defineAsyncComponent(
  () => import('@/components/basic/molecules/MultiTools.vue')
)
const Tool = defineAsyncComponent(
  () => import('@/components/basic/atoms/Tool.vue')
)

const getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ default: TrackingHelper }, { default: LozadHelper }, { TYPES }] =
      await Promise.all([
        await import('@/services/helpers/TrackingHelper'),
        await import('@/services/helpers/LozadHelper'),
        await import('@/constants/AdminTool.js')
      ])
    cache = { TrackingHelper, LozadHelper, TYPES }
    return cache
  }
})()

const props = defineProps({
  pageId: {
    type: String,
    default: null
  }
})
const customer = useCustomerStore()
const { page, mainContent, mainContentCss, mainContentJs } =
  storeToRefs(useCmsPagesStore())

const el = ref(null)
const state = reactive({
  pageLoaded: false,
  adminTool: null,
  js: '',
  css: ''
})
const processorId = computed(() => `page-${props.pageId}`)

const setupPage = () => {
  state.css = mainContentCss.value
  state.js = mainContentJs.value
  trackCmsPageView()
}
useCss(state)
useJs(state)

const trackCmsPageView = async () => {
  const { TrackingHelper } = await getModules()
  TrackingHelper.sendCmsPageView(page.value.page_id)
}

const rendered = async () => {
  const { LozadHelper } = await getModules()
  state.pageLoaded = true
  await LozadHelper.hitLozad()
  useTracking(el)
}

onMounted(async () => {
  setupPage()
  const { TYPES } = await getModules()
  state.adminTool = TYPES
})
</script>

<template>
  <div class="position-relative" ref="el">
    <transition name="fade">
      <div v-if="mainContent" v-show="state.pageLoaded">
        <Processor
          :template="mainContent"
          :identifier="processorId"
          @rendered="rendered"
        />
      </div>
    </transition>
    <LoadingOverlay
      v-show="!state.pageLoaded"
      class="position-relative my-5"
      :use-background="false"
    />
    <MultiTools
      id="fixed-tools"
      v-if="state.adminTool && customer.customerIsAdmin"
    >
      <Tool :type="state.adminTool.TRANSLATE_MODE" />
      <Tool :type="state.adminTool.HIGHLIGHT_CMS_ITEMS" />
      <Tool :type="state.adminTool.TOGGLE_DATALAYER_LOGGING" />
      <Tool :type="state.adminTool.TOGGLE_ELBWALKER_LOGGING" />
      <Tool :type="state.adminTool.EDIT_CMS" :id="page.page_id" />
      <Tool :type="state.adminTool.TOGGLE_HIGH_CONTRAST" />
      <Tool :type="state.adminTool.TEXT_INCREASE" />
      <Tool :type="state.adminTool.TEXT_PERCENTAGE" />
      <Tool :type="state.adminTool.TEXT_DECREASE" />
    </MultiTools>
  </div>
</template>

<style scoped>
.fade-enter-active {
  transition: opacity 1s var(--transition-timing-cb-vicampo) 0.7s;
}

.fade-leave-active {
  transition: opacity 0.5s var(--transition-timing-cb-vicampo);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
