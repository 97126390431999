import { isApiProduct } from '@/services/helpers/ProductHelper'

export const WALKER_COMMANDS = {
  init: 'walker init',
  consent: 'walker consent',
  run: 'walker run',
  destination: 'walker destination'
}

class ElbwalkerHelper {
  constructor() {
    if (ElbwalkerHelper.Singleton) return
    ElbwalkerHelper.Singleton = this
  }

  getProductData(product) {
    let data

    if (isApiProduct(product)) {
      data = {
        product_id: product?.product_id,
        campaign_id: product?.campaign_id,
        name: `'${product?.name}'`,
        type: product?.type,
        price: product?.original_price,
        special_price: product?.final_price,
        sku: product?.sku,
        wine_type: product?.details?.wine_type,
        country: product?.details?.country,
        vendor_name:
          product?.stocks?.length > 0 ? product.stocks[0].name : undefined
      }
    } else {
      data = {
        product_id: product?.product_id,
        campaign_id: product?.campaign_id,
        name: `'${product?.name}'`,
        type: product?.type,
        price: product?.price?.original,
        special_price: product?.price?.final
      }
    }

    return Object.entries(data)
      .map(([key, value]) => `${key}:${value}`)
      .join(';')
  }
}

export default new ElbwalkerHelper()
