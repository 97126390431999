import { nextTick, watch } from 'vue'

const useJs = (state) => {
  watch(() => state.js, _run, { immediate: true })
}

const _run = async (js) => {
  await nextTick()
  Function(js)()
}

export { useJs }
