<template>
  <div
    class="position-relative"
    id="content"
    data-elbglobals="page_type:category"
    data-elbcontext="shopping:discover"
  >
    <AppBanner />
    <AlertBanner
      v-if="!pageIsVisible"
      :text="translate('alert_message_preview_mode')"
    />
    <UspBar />
    <TheHeader class="position-relative" />
    <main id="page-template">
      <TheCategoryTemplate />
    </main>
    <LazyComponent id="footer" :delay="2000">
      <TheFooter />
    </LazyComponent>
  </div>
</template>

<script>
import UspBar from '@/components/basic/atoms/UspBar.vue'
import TheHeader from '@/components/basic/organisms/TheHeader.vue'
import TheCategoryTemplate from '@/components/category/templates/TheCategoryTemplate.vue'
import {
  containsPrivateInfo,
  NO_INDEX_FOLLOW
} from '@/services/helpers/MetaHelper.js'
import { useSearchConfig } from '@/stores/searchConfig'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'
import { useRecommendationStore } from '../stores/recommendations.js'
const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const AppBanner = defineAsyncComponent(
  () => import('@/components/basic/molecules/AppBanner.vue')
)
const AlertBanner = defineAsyncComponent(
  () => import('@/components/basic/atoms/AlertBanner.vue')
)
const TheFooter = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheFooter.vue')
)

const NAME = 'TheCategoryPage'

export default {
  head() {
    return {
      title: this.title,
      meta: [
        {
          name: 'robots',
          content: containsPrivateInfo()
            ? NO_INDEX_FOLLOW
            : this.category.robots
        },
        { name: 'description', content: this.category.meta_description }
      ]
    }
  },
  name: NAME,
  components: {
    AppBanner,
    AlertBanner,
    TheHeader,
    TheCategoryTemplate,
    LazyComponent,
    TheFooter,
    UspBar
  },
  computed: {
    ...mapState(useSearchConfig, ['category', 'getConfig', 'resetConfig']),
    footer() {
      return TheFooter
    },
    title() {
      return this.category.meta_title || this.category.name || ''
    },
    pageIsVisible() {
      return this.category.is_enabled
    }
  },
  methods: {
    ...mapActions(useSearchConfig, ['fetchCategory'])
  },
  beforeRouteEnter(_t, _f, next) {
    next((vm) => {
      vm.fetchCategory()
    })
  },
  beforeCreate() {
    const recommendations = useRecommendationStore()
    recommendations.fetch()
  },
  beforeRouteLeave() {
    this.resetConfig()
  }
}
</script>
