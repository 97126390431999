<template>
  <div>
    <TheCheckoutHeader :currentStep="currentStep" />
    <component :is="currentComponent" />
  </div>
</template>

<script>
import TheCheckoutAddress from '@/components/checkout/organisms/TheCheckoutAddress.vue'
import TheCheckoutDelivery from '@/components/checkout/organisms/TheCheckoutDelivery.vue'
import TheCheckoutHeader from '@/components/checkout/organisms/TheCheckoutHeader.vue'
import TheCheckoutLogin from '@/components/checkout/organisms/TheCheckoutLogin.vue'
import TheCheckoutPayment from '@/components/checkout/organisms/TheCheckoutPayment.vue'
import TheCheckoutReview from '@/components/checkout/organisms/TheCheckoutReview.vue'

export default {
  props: ['step'],
  components: {
    TheCheckoutHeader,
    TheCheckoutLogin,
    TheCheckoutAddress,
    TheCheckoutPayment,
    TheCheckoutDelivery,
    TheCheckoutReview
  },
  computed: {
    currentStep() {
      switch (this.step) {
        case 'login':
          return 1
        case 'address':
          return 2
        case 'payment':
          return 3
        case 'delivery':
          return 4
        case 'review':
          return 5
        default:
          return 1
      }
    },
    currentComponent() {
      switch (this.step) {
        case 'login':
          return TheCheckoutLogin
        case 'address':
          return TheCheckoutAddress
        case 'payment':
          return TheCheckoutPayment
        case 'delivery':
          return TheCheckoutDelivery
        case 'review':
          return TheCheckoutReview
        default:
          return TheCheckoutLogin
      }
    }
  }
}
</script>
