<template>
  <VContainer
    class="pt-2"
    data-elbcontext="shopping:discover;component:wishlist"
  >
    <div class="v-row">
      <h3
        id="items"
        class="v-col-6 v-col-lg-8 v-col-xl-9 font-size-xl c-gray my-3 l-spacing-1 font-weight-bold"
      >
        {{ translate('new_cart_page_wishlist') }}
      </h3>
    </div>
    <div class="v-row flex-row flex-md-row-reverse">
      <div class="sidebar v-col-12 v-col-md-4 v-col-lg-3">
        <div class="v-row position-sticky">
          <TheCartAwards v-if="showCartAwards" class="w-100" />
        </div>
      </div>
      <div class="v-col-12 v-col-md-8 v-col-lg-9">
        <div class="v-row" v-if="totalCount < 1 && isUpdating">
          <div class="v-col-12 px-0 px-md-3">
            <LoadingOverlay
              class="bg-white border-b mt-2 mt-lg-0 pa-3 position-relative"
              width="140"
              height="140"
            />
          </div>
        </div>
        <div v-else-if="totalCount" class="v-row">
          <TheWishlistItemsSection
            v-if="inStockTotalCount"
            class="v-col-12 px-0 px-md-3"
            item-list-id="wishlist"
          />
          <TheWishlistSoldOutItemsSection
            v-if="soldOutTotalCount"
            class="v-col-12 px-0 px-md-3"
            item-list-id="wishlist_sold_out"
          />
        </div>
        <div class="mx-md-0" v-else>
          <TheEmptyInfo
            class="v-col-12 px-0 px-md-3"
            icon="icon_svg_heart"
            headline="empty_wishlist"
            subline="empty_wishlist_deals"
            button-title="new_cart_empty_cart_button"
            :button-link="emptyLink"
          />
        </div>
      </div>
    </div>
  </VContainer>
</template>

<script>
import { useWishlistStore } from '@/stores/wishlist.js'
import { mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

const TheEmptyInfo = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheEmptyInfo.vue')
)
const TheCartAwards = defineAsyncComponent(
  () => import('@/components/cart/organisms/TheCartAwards.vue')
)
const LoadingOverlay = defineAsyncComponent(
  () => import('@/components/basic/atoms/LoadingOverlay.vue')
)
const TheWishlistItemsSection = defineAsyncComponent(
  () => import('@/components/wishlist/organisms/TheWishlistItemsSection.vue')
)
const TheWishlistSoldOutItemsSection = defineAsyncComponent(
  () =>
    import('@/components/wishlist/organisms/TheWishlistSoldOutItemsSection.vue')
)

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const { default: SiteConfig } = await import('@/services/SiteConfig')
    cache = { SiteConfig }
    return cache
  }
})()

export default {
  components: {
    TheEmptyInfo,
    TheWishlistSoldOutItemsSection,
    TheWishlistItemsSection,
    TheCartAwards,
    LoadingOverlay
  },
  data() {
    return {
      emptyLink: ''
    }
  },
  computed: {
    ...mapState(useWishlistStore, {
      isUpdating: 'updating',
      totalCount: 'totalCount',
      inStockTotalCount: 'inStockTotalCount',
      soldOutTotalCount: 'soldOutTotalCount'
    }),
    offersPath() {
      return this.getVariable('offers_path')
    },
    showCartAwards() {
      return this.$vuetify.display.mdAndUp
    }
  },
  watch: {
    async offersPath(relativePath) {
      const {
        SiteConfig: { pageRoot }
      } = await _getModules()
      this.emptyLink = `${pageRoot}${relativePath}`
    }
  }
}
</script>
