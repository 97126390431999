<template>
  <div class="position-relative">
    <LoadingOverlay v-if="loading" />
    <TheOpcHeader />
    <main
      id="page-template"
      :class="{ 'pointer-events-none': paymentInProgress }"
    >
      <TheOpcStage data-elblink="product:child" />
      <TheOpcErrorMessage />
      <TheOpcBody data-elblink="product:parent" />
      <LazyComponent id="opc-footer" :delay="1500">
        <TheOpcFooter />
      </LazyComponent>
    </main>
    <MultiTools id="fixed-tools" v-if="customerIsAdmin && productLoaded">
      <Tool :type="adminTool.TRANSLATE_MODE" />
      <Tool :type="adminTool.HIGHLIGHT_CMS_ITEMS" />
      <Tool :type="adminTool.TOGGLE_DATALAYER_LOGGING" />
      <Tool :type="adminTool.TOGGLE_ELBWALKER_LOGGING" />
      <Tool
        :type="adminTool.EDIT_PRODUCT"
        :id="product.product_id"
        :campaign-id="product.campaign_id"
      />
      <Tool :type="adminTool.TOGGLE_HIGH_CONTRAST" />
      <Tool :type="adminTool.TEXT_INCREASE" />
      <Tool :type="adminTool.TEXT_PERCENTAGE" />
      <Tool :type="adminTool.TEXT_DECREASE" />
    </MultiTools>
    <TheOpcSoldOutOverlay
      v-if="soldOut"
      :show-hide-button="customerIsAdmin"
      @hide="soldOut = false"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import { TYPES } from '@/constants/AdminTool'
import { useCheckoutStore } from '@/stores/checkout'
import { useCustomerStore } from '@/stores/customer'
import { useOpcStore } from '@/stores/opc'
import { mapState } from 'pinia'

const TheOpcStage = defineAsyncComponent(
  () => import('@/components/opc/molecules/TheOpcStage.vue')
)
const TheOpcHeader = defineAsyncComponent(
  () => import('@/components/opc/organisms/TheOpcHeader.vue')
)
const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const MultiTools = defineAsyncComponent(
  () => import('@/components/basic/molecules/MultiTools.vue')
)
const Tool = defineAsyncComponent(
  () => import('@/components/basic/atoms/Tool.vue')
)
const TheOpcBody = defineAsyncComponent(
  () => import('@/components/opc/organisms/TheOpcBody.vue')
)
const TheOpcFooter = defineAsyncComponent(
  () => import('@/components/opc/organisms/TheOpcFooter.vue')
)
const TheOpcErrorMessage = defineAsyncComponent(
  () => import('@/components/opc/atoms/TheOpcErrorMessage.vue')
)
const TheOpcSoldOutOverlay = defineAsyncComponent(
  () => import('@/components/opc/organisms/TheOpcSoldOutOverlay.vue')
)
const LoadingOverlay = defineAsyncComponent(
  () => import('@/components/basic/atoms/LoadingOverlay.vue')
)

export default {
  name: 'TheOpcTemplate',
  components: {
    LoadingOverlay,
    TheOpcSoldOutOverlay,
    TheOpcErrorMessage,
    Tool,
    MultiTools,
    TheOpcStage,
    TheOpcHeader,
    LazyComponent,
    TheOpcBody,
    TheOpcFooter
  },
  data() {
    return {
      adminTool: TYPES,
      soldOut: false
    }
  },
  computed: {
    ...mapState(useOpcStore, ['product', 'loading']),
    ...mapState(useCheckoutStore, ['paymentInProgress']),
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    productLoaded() {
      return this.product.product_id && this.product.campaign_id
    }
  },
  watch: {
    productLoaded() {
      this.soldOut = !this.product.stocks.some(
        ({ is_in_stock: isInStock }) => isInStock
      )
    },
    soldOut(isSoldOut) {
      document.body.style.overflow = isSoldOut ? 'hidden' : null
      document.body.style.height = isSoldOut ? '100vh' : null
    }
  }
}
</script>
