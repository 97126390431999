import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
export const loadNewRelic = async () => {
  if (import.meta.env.VITE_APP_DEV_MODE === 'ON') {
    console.log(`%c New Relic is disabled in dev mode`, 'color: orange;')
    return
  }
  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true }
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: `${import.meta.env.VITE_NEW_RELIC_LICENSE_KEY}`,
      applicationID: `${import.meta.env.VITE_NEW_RELIC_APPLICATION_ID}`,
      sa: 1
    },
    loader_config: {
      accountID: `${import.meta.env.VITE_NEW_RELIC_ACCOUNT_ID}`,
      trustKey: `${import.meta.env.VITE_NEW_RELIC_TRUST_KEY}`,
      agentID: `${import.meta.env.VITE_NEW_RELIC_AGENT_ID}`,
      licenseKey: `${import.meta.env.VITE_NEW_RELIC_LICENSE_KEY}`,
      applicationID: `${import.meta.env.VITE_NEW_RELIC_APPLICATION_ID}`
    }
  }

  new BrowserAgent(options)
}
