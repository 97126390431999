import { defineStore } from 'pinia'

const FONT_SIZE_KEYS = [
  'xxxxxl-font-size',
  'xxxxl-font-size',
  'xxxl-font-size',
  'xxl-font-size',
  'xl-font-size',
  'l-font-size',
  'm-font-size',
  'regular-font-size',
  's-font-size',
  'xs-font-size',
  'xxs-font-size'
]

export const HIGH_CONTRAST_TOGGLE = 'show_high_contrast'

export const useAccessibilityStore = defineStore('accessibility', {
  state: () => {
    const state = {
      originalFontSizes: {},
      fontSizePercentage: 100,
      highContrastEnabled:
        localStorage.getItem(HIGH_CONTRAST_TOGGLE) === 'true',
      styleTag: null
    }

    FONT_SIZE_KEYS.forEach((key) => {
      state.originalFontSizes[key] = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(`--${key}`)
      )
    })

    const savedPercentage = localStorage.getItem('fontSizePercentage')
    if (savedPercentage !== null) {
      state.fontSizePercentage = parseInt(savedPercentage)
    }

    return state
  },
  getters: {
    fontSizeDifference: (state) => {
      return state.fontSizePercentage
    }
  },
  actions: {
    changeFontSize(step) {
      this.fontSizePercentage += step
      this.updateCSSVariables()
    },
    updateCSSVariables() {
      if (!this.styleTag) {
        const headTag = document.getElementsByTagName('head')[0]
        this.styleTag = document.createElement('style')
        headTag.appendChild(this.styleTag)
      }

      let cssVariables = ':root {'
      FONT_SIZE_KEYS.forEach((key) => {
        const newSize =
          (this.originalFontSizes[key] * this.fontSizePercentage) / 100
        cssVariables += `--${key}: ${newSize}px; `
      })
      cssVariables += '}'

      this.styleTag.innerHTML = cssVariables

      localStorage.setItem('fontSizePercentage', this.fontSizePercentage)
    },
    setHighContrast(enabled) {
      this.highContrastEnabled = enabled
      enabled
        ? localStorage.setItem(HIGH_CONTRAST_TOGGLE, 'true')
        : localStorage.removeItem(HIGH_CONTRAST_TOGGLE)
    }
  }
})
