<template>
  <IconDown rotate="90" />
</template>

<script>
import { defineComponent } from 'vue'
import IconDown from './IconDown.vue'

export default defineComponent({
  components: { IconDown }
})
</script>
