import { loadCookiefirst } from '@/external_scripts/cookiefirst'
import { loadElbwalker } from '@/external_scripts/elbwalker'
import { loadGtm } from '@/external_scripts/gtm'
import { loadVWO } from '@/external_scripts/vwo'
import { loadNewRelic } from './newrelic.js'

const CF_CONSENT_EVENT = 'cf_consent'
const CF_CONSENT_INIT = 'cf_init'

export const injectScripts = async () => {
  window.addEventListener(CF_CONSENT_EVENT, () => {
    loadScripts()
  })
  window.addEventListener(CF_CONSENT_INIT, () => {
    loadScripts()
  })
  loadNewRelic()
  await loadCookiefirst()
}

async function loadScripts() {
  const { default: CF } = await import('@/services/helpers/CookieFirstHelper')

  loadElbwalker(CF.getConsent())

  if (CF.getPerformanceConsent()) {
    await loadVWO()
  }

  if (CF.getFunctionalConsent()) {
    await loadGtm()
  }
}

injectScripts()
