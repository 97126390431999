<script setup>
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min.js'
import {
  computed,
  defineAsyncComponent,
  onBeforeMount,
  onMounted,
  ref
} from 'vue'

import IconSetHeart from '@/components/basic/atoms/IconSetHeart.vue'
import IconSetWeb from '@/components/basic/atoms/IconSetWeb.vue'
import Overlay from '@/components/basic/atoms/Overlay.vue'
import { useGlobals } from '@/composables/useGlobals.js'
import SiteConfig from '@/services/SiteConfig.js'
import { useCmsBlocks } from '@/stores/cmsBlocks.js'
import { useCmsVariables } from '@/stores/cmsVariables.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useFeaturesStore } from '@/stores/features.js'
import { useHead } from '@unhead/vue'
import { storeToRefs } from 'pinia'
import { useDisplay, useTheme } from 'vuetify'
import { useAccessibilityStore } from './stores/accessibility.js'
import { useCartStore } from './stores/cart.js'

const ThePopoverWrapper = defineAsyncComponent(
  () => import('@/components/basic/molecules/ThePopoverWrapper.vue')
)
const TheCountrySwitcher = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheCountrySwitcher.vue')
)
const AlertBanner = defineAsyncComponent(
  () => import('@/components/basic/atoms/AlertBanner.vue')
)
const Dialog = defineAsyncComponent(
  () => import('@/components/basic/organisms/Dialog.vue')
)
const TheAuthenticationWrapper = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheAuthenticationWrapper.vue')
)
const { translate } = useGlobals()
const customer = useCustomerStore()
const blocks = useCmsBlocks()
const variables = useCmsVariables()
const features = useFeaturesStore()
const cart = useCartStore()
const display = useDisplay()
const theme = useTheme()
const accessibility = useAccessibilityStore()

const HIGH_CONTRAST_TOGGLE = 'show_high_contrast'
theme.global.name.value = localStorage.getItem(HIGH_CONTRAST_TOGGLE)
  ? 'vicampoThemeHighContrast'
  : 'vicampoThemeRebrand'

const { customerProfile, isLoginShowing } = storeToRefs(customer)
const countrySwitcherData = ref(null)

const isLoggedInAsCustomer = computed(
  () => customerProfile.admin_data?.admin_customer_id
)
const countrySwitcherTitle = computed(() => {
  const switchTo = translate(
    'fe_app_country_' + countrySwitcherData.value?.siteId
  )
  return translate('fe_app_country_switcher_header', switchTo)
})

const displayClassUp = computed(() => {
  const width = display.width.value
  const thresholds = display.thresholds.value

  switch (true) {
    case width >= thresholds.xxl:
      return 'display-xxl-and-up display-xl-and-up display-lg-and-up display-md-and-up display-sm-and-up display-xs-and-up'
    case width >= thresholds.xl:
      return 'display-xl-and-up display-lg-and-up display-md-and-up display-sm-and-up display-xs-and-up'
    case width >= thresholds.lg:
      return 'display-lg-and-up display-md-and-up display-sm-and-up display-xs-and-up'
    case width >= thresholds.md:
      return 'display-md-and-up display-sm-and-up display-xs-and-up'
    case width >= thresholds.sm:
      return 'display-sm-and-up display-xs-and-up'
    case width >= thresholds.xs:
      return 'display-xs-and-up'
    default:
      return ''
  }
})

const updateShowLogin = (visible) => {
  customer.showLogin(visible)
}

onBeforeMount(async () => {
  await customer.establishSession()

  void variables.fetchDefaults()
  void blocks.fetchDefaults()
  void features.refresh()

  const [{ default: TagManager }, { default: SiteConfig }] = await Promise.all([
    await import('@/services/analytics/TagManager'),
    await import('@/services/SiteConfig')
  ])

  TagManager.pushToDataLayer({ application: 'fe-app' })
  countrySwitcherData.value = await SiteConfig.offerOtherSite()

  // refresh session as soon as the tab becomes active again
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState !== 'visible') return
    customer.establishSession()
  })
})

onMounted(async () => {
  const [{ default: CouponHelper }] = await Promise.all([
    await import('@/services/helpers/CouponHelper')
  ])
  const coupon = await CouponHelper.checkForCoupon()
  void cart.applyCouponCode(coupon)

  accessibility.updateCSSVariables()

  SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    header: '.navbar > div',
    easing: 'easeInOutCubic',
    updateURL: false
  })
})
useHead({
  titleTemplate: `${SiteConfig.devMode ? '[ 👾] ' : ''}%s | ${SiteConfig.name} `
})
</script>
<template>
  <VApp id="root" :class="displayClassUp">
    <AlertBanner
      v-if="isLoggedInAsCustomer"
      :text="translate('fe_app_logged_in_as', customerProfile.email)"
    />
    <RouterView />
    <Overlay />
    <IconSetHeart />
    <IconSetWeb />
    <Dialog
      v-if="countrySwitcherData"
      :title="countrySwitcherTitle"
      v-model="countrySwitcherData"
      @close="countrySwitcherData = null"
    >
      <TheCountrySwitcher
        :country-switcher-data="countrySwitcherData"
        @close="countrySwitcherData = null"
      />
    </Dialog>
    <Dialog v-model="isLoginShowing" @close="updateShowLogin(false)">
      <TheAuthenticationWrapper
        class="flex-grow-1"
        @success="updateShowLogin(false)"
      />
    </Dialog>
    <ThePopoverWrapper :show-nps="false" />
  </VApp>
</template>
