<template>
  <header id="header-wrapper" class="mb-2">
    <div class="content d-flex flex-column align-center">
      <div id="checkout-header" class="v-row justify-center py-4 text-center">
        <VicampoLink
          href="/"
          itemscope
          itemtype="https://schema.org/Brand"
          class="v-col-8 v-col-md-5 mb-3 mb-md-0"
        >
          <img :src="logoSrc" class="w-75 h-75" alt="vicampo" />
        </VicampoLink>
        <div class="v-col-12 v-col-md-6">
          <TheCheckoutStepper :initialStep="currentStep" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import logoMain from '@/assets/images/logo-main.svg'
import TheCheckoutStepper from '@/components/checkout/molecules/TheCheckoutStepper.vue'
const VicampoLink = () => import('@/components/basic/atoms/VicampoLink.vue')

export default {
  name: 'TheCheckoutHeader',
  props: ['currentStep'],
  components: {
    TheCheckoutStepper,
    VicampoLink
  },
  computed: {
    logoSrc() {
      return logoMain
    }
  }
}
</script>

<style scoped>
.content {
  position: relative;
  background: rgb(var(--v-theme-white));
  min-height: 130px;
}

.display-md-and-up .content {
  min-height: 98px;
}

#header-wrapper {
  margin-bottom: 5px;
}

.display-md-and-up #header-wrapper {
  margin-bottom: inherit;
}

#checkout-header > div,
#checkout-header > form {
  display: flex;
  align-items: center;
  height: 75px;
}
</style>
