<template>
  <div
    itemscope
    itemtype="https://schema.org/ContactPoint"
    id="usp-bar"
    class="bg-chalkboard text-center d-flex"
  >
    <VContainer class="d-inline-block c-ash-gray font-size-s align-center pa-0">
      <div class="d-flex justify-center align-center h-100 w-100">
        <div class="d-none d-md-flex align-center ml-2 flex-grow-1">
          <SvgWrapper
            id="icon-phone"
            class="f-ash-gray d-inline-block float-left"
            use="icon_svg_phone"
            dimensions="20"
          />
          <div class="text-uppercase font-weight-bold">
            <span class="d-inline-block float-left ml-1 text-uppercase">
              {{ translate('fe_app_pdp_customer_service') }}
            </span>
            <span
              itemprop="telephone"
              class="d-inline-block float-left ml-1 f-white"
              >{{ phoneNumber }}</span
            >
          </div>
        </div>
        <div
          v-if="this.$vuetify.display.lgAndUp || !customerProfile.salutation"
          class="d-flex justify-center align-center flex-grow-1"
        >
          <SvgWrapper
            use="icon_svg_delivery_free"
            dimensions="30"
            class="flex-shrink-0"
            :style="{
              fill: `rgb(var(--v-theme-white))`
            }"
          />
          <div>
            <div class="font-size-s font-weight-bold text-uppercase ml-3">
              {{ translate('free_shipping') }}
              {{ translate('shipping_subline') }}
            </div>
          </div>
        </div>
        <div
          v-if="this.$vuetify.display.lgAndUp && !customerProfile.salutation"
          class="d-flex align-center justify-md-end justify-center flex-grow-1 mr-5"
        >
          <SvgWrapper
            use="icon_svg_money_return"
            dimensions="25"
            class="flex-shrink-0"
            :style="{
              fill: `rgb(var(--v-theme-white))`
            }"
          />
          <div>
            <div class="font-size-sm-s font-size-xs font-weight-bold ml-3">
              {{ translate('cash_back_warranty') }}
            </div>
          </div>
        </div>
        <div
          v-if="customerProfile.salutation"
          class="d-flex align-center h-100 justify-center px-2 bg-primary"
          :class="{
            'w-100': this.$vuetify.display.smAndDown
          }"
        >
          <span
            v-if="customerProfile.salutation"
            class="usp-badge font-weight-bold"
          >
            {{ salutation }}
          </span>
          <span
            v-if="
              this.$vuetify.display.smAndUp && customerProfile.credits_formatted
            "
            :class="{
              'd-flex justify-start': this.$vuetify.display.smAndDown
            }"
            class="usp-badge highlight overflow-hidden align-self-start ml-1"
          >
            {{ creditsMessage }}
          </span>
          <span
            v-else-if="
              this.$vuetify.display.smAndUp && customerProfile.meta_navigation
            "
            class="link-white ml-1"
            v-html="customerProfile.meta_navigation"
          ></span>
        </div>
        <a
          :href="adminUrl"
          v-if="adminUrl && customerProfile.is_admin"
          class="d-none d-md-inline mx-2 font-weight-bold link-ash-gray"
          >Admin</a
        >
      </div>
    </VContainer>
  </div>
</template>

<script>
import SvgWrapper from '@/components/basic/atoms/SvgWrapper.vue'
import { useCustomerStore } from '@/stores/customer'
import { mapState } from 'pinia'

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      {
        PAGE_URL: { ADMIN: adminUrl }
      },
      {
        default: {
          phone: { text: phoneNumber }
        }
      }
    ] = await Promise.all([
      import('@/constants/GlobalConstants'),
      import('@/services/SiteConfig')
    ])

    cache = { adminUrl, phoneNumber }
    return cache
  }
})()

export default {
  components: { SvgWrapper },
  data() {
    return {
      adminUrl: null,
      phoneNumber: ''
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerProfile']),
    salutation() {
      const salutation = this.translate(
        'header_usp_bar_customer_info_customer_salutation',
        this.customerProfile.salutation
      )
      return this.$vuetify.display.smAndUp ? `${salutation},` : salutation
    },
    creditsMessage() {
      let key
      let data = [this.customerProfile.credits_formatted]
      if (this.customerProfile.credits_expire_days_left === null) {
        key = 'header_usp_bar_customer_info_customer_credits'
      } else if (this.customerProfile.credits_expire_days_left > 1) {
        key = 'header_usp_bar_customer_info_credits_expire_in_more_days'
        data = [...data, this.customerProfile.credits_expire_days_left]
      } else if (this.customerProfile.credits_expire_days_left === 1) {
        key = 'header_usp_bar_customer_info_credits_expire_in_one_day'
        data = [...data, 1]
      } else {
        key = 'header_usp_bar_customer_info_credits_expire_today'
      }
      return this.translate(key, ...data)
    }
  },
  async created() {
    const { adminUrl, phoneNumber } = await _getModules()
    this.phoneNumber = phoneNumber
    this.adminUrl = adminUrl
  }
}
</script>

<style scoped>
.usp-badge {
  color: rgb(var(--v-theme-white));
  background-color: rgb(var(--v-theme-primary));
  line-height: 30px;
  font-weight: bold;
}

#usp-bar {
  height: 30px;
}

#icon-phone {
  height: 30px;
}
</style>
