<template>
  <VContainer
    id="product-stage"
    class="pa-0"
    data-elb="product"
    :data-elb-product="elbData"
    v-resize="onResize"
  >
    <VSheet class="d-flex flex-column overflow-hidden">
      <VRow>
        <TheBanners v-if="!loading" />
      </VRow>
      <VRow class="d-flex px-3">
        <div
          class="v-col-12 v-col-lg-6 image-wrapper position-relative overflow-hidden"
        >
          <Grape
            class="bottom-0 top-0 left-0 right-0 position-absolute"
            color="light-beige"
          />
          <VImg
            ref="pImg"
            :alt="product.name"
            :lazy-src="placeholderImage"
            :src="productImage"
            itemprop="image"
            class="product-image pb-lg-3"
            itemtype="https://schema.org/image"
            :content="productImage"
            :class="{
              'pt-lg-5': !isBundle,
              'high-contrast': highContrastEnabled
            }"
            :height="$vuetify.display.smAndDown ? 360 : 600"
          />
          <div class="wishlist-icon">
            <AddToWishlist
              class="flex-row-reverse f-gray c-gray"
              :product="metaProduct"
            />
          </div>
          <AwardWrapper
            v-if="!loading"
            class="award-list mt-4 pl-3 position-absolute"
            :awards="product.awards"
            :threshold="3"
          />
        </div>
        <div
          class="v-col-12 v-col-lg-6 py-4 c-darker font-size-regular d-flex justify-center px-lg-0"
        >
          <div class="v-col-12 v-col-sm-9 v-col-lg-10 px-0">
            <div class="d-flex flex-column align-center align-lg-start">
              <h2
                v-if="product.manufacturer_name"
                itemprop="brand"
                itemscope=""
                itemtype="https://schema.org/Brand"
                class="mb-0"
              >
                <VicampoLink
                  v-if="showManufacturer"
                  id="manufacturer"
                  class="text-uppercase manufacturer-name link-gray"
                  :loading="loading"
                  itemprop="url"
                  :href="manufacturerUrl"
                >
                  <span itemprop="name">{{ product.manufacturer_name }}</span>
                </VicampoLink>
                <span
                  v-else
                  class="manufacturer-name text-uppercase c-gray"
                  :loading="loading"
                >
                  {{ product.manufacturer_name }}
                </span>
              </h2>
              <h1
                itemprop="name"
                id="stage-name"
                class="font-weight-bold mt-3 font-size-xl text-center text-lg-left mb-0"
                :loading="loading"
              >
                {{ product.name }}
              </h1>
            </div>
            <AggregatedRating
              v-if="!loading && productV1.details.customer_rating"
              class="my-4 justify-center justify-lg-start"
              include-props
              :rating-data="{
                ratingsAverage: productV1.details.customer_rating,
                ratingsCount: productV1.details.customer_rating_count
              }"
              :show-dropdown-icon="false"
              @click="
                goTo('#customer-reviews', {
                  offset: $vuetify.display.lgAndUp ? -85 : 0
                })
              "
            />
            <div
              itemprop="description"
              id="reason-why-one"
              :loading="loading"
              class="text-left mt-2 mb-3"
            >
              <p class="styled-text ma-0" v-html="product.reason_why_one"></p>
            </div>
            <Price
              v-if="!$vuetify.display.mdAndDown"
              :loading="loading"
              class="py-2"
              :price="calculated.priceObject"
              :stock="stock"
              include-props
            />
            <MilesAndMoreBanner
              :earn="milesAndMoreData.earn"
              :spend="milesAndMoreData.spend"
            />
            <DeliveryInfo
              v-if="stock"
              class="pt-3"
              :loading="loading"
              :stock="stock"
            />
            <Cta
              v-if="!$vuetify.display.mdAndDown && !loading"
              class="pt-2"
              :pre-selected-qty="calculated.preSelectedQty"
              :product="product"
            />
            <Legals class="d-flex flex-column align-center align-lg-start" />
          </div>
        </div>
      </VRow>
    </VSheet>
  </VContainer>
</template>

<script>
import { API_URL } from '@/constants/GlobalConstants.js'
import ElbwalkerHelper from '@/services/helpers/ElbwalkerHelper'
import UtilityHelper from '@/services/helpers/UtilityHelper.js'
import { useAccessibilityStore } from '@/stores/accessibility.js'
import { usePdpStore } from '@/stores/pdp'
import { mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'
import { useGoTo } from 'vuetify'

const Grape = defineAsyncComponent(
  () => import('@/components/basic/atoms/Grape.vue')
)
const Legals = defineAsyncComponent(
  () => import('@/components/pdp/atoms/Legals.vue')
)
const AddToWishlist = defineAsyncComponent(
  () => import('@/components/basic/atoms/AddToWishlist.vue')
)
const AggregatedRating = defineAsyncComponent(
  () => import('@/components/basic/molecules/AggregatedRating.vue')
)
const TheBanners = defineAsyncComponent(
  () => import('@/components/pdp/molecules/TheBanners.vue')
)
const AwardWrapper = defineAsyncComponent(
  () => import('@/components/basic/molecules/AwardWrapper.vue')
)
const Cta = defineAsyncComponent(
  () => import('@/components/basic/molecules/Cta.vue')
)
const DeliveryInfo = defineAsyncComponent(
  () => import('@/components/basic/molecules/DeliveryInfo.vue')
)
const MilesAndMoreBanner = defineAsyncComponent(
  () => import('@/components/basic/molecules/MilesAndMoreBanner.vue')
)
const Price = defineAsyncComponent(
  () => import('@/components/basic/molecules/Price.vue')
)
const VicampoLink = defineAsyncComponent(
  () => import('@/components/basic/atoms/VicampoLink.vue')
)

const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

export default {
  name: 'ProductStage',
  components: {
    Grape: Grape,
    VicampoLink,
    AddToWishlist,
    TheBanners,
    Cta,
    DeliveryInfo,
    MilesAndMoreBanner,
    Price,
    AggregatedRating,
    AwardWrapper,
    Legals
  },
  data() {
    return {
      imgWidth: 0,
      imgHeight: 0
    }
  },
  computed: {
    ...mapState(useAccessibilityStore, ['highContrastEnabled']),
    ...mapState(usePdpStore, {
      product: 'getApiProduct',
      productV1: 'getApiV1Product',
      metaProduct: 'getMetaProduct',
      calculated: 'getCalculated',
      stock: 'getFirstAvailableStock',
      loading: 'isUpdating'
    }),
    productImage() {
      const productId = this.$router.currentRoute.value.params.pId
      const campaignId = this.$router.currentRoute.value.params.cId
      const type = this.$vuetify.display.smAndDown ? 'small_image' : 'image'
      const density = window.devicePixelRatio ?? 1
      const appendix = `?type=${type}&height=${this.imgHeight * density}&width=${this.imgWidth * density}`
      return UtilityHelper.formatString(
        API_URL.CATALOG.PRODUCT.GET_V2_IMAGE,
        productId,
        campaignId,
        appendix
      )
    },
    placeholderImage() {
      return new URL('@/assets/images/bottle.svg', import.meta.url).href
    },
    milesAndMoreData() {
      return this.metaProduct.milesandmore
    },
    recommendation() {
      return this.metaProduct.recommendation
    },
    manufacturerUrl() {
      return this.product.manufacturer_link
    },
    isBundle() {
      return this.calculated.isBundle
    },
    tags() {
      return this.calculated.tags
    },
    elbData() {
      return ElbwalkerHelper.getProductData(this.product)
    },
    showManufacturer() {
      return this.product.manufacturer_enabled
    }
  },
  methods: {
    initImageDimensions() {
      this.imgWidth = this.$refs.pImg.$el.clientWidth
      this.imgHeight = this.$refs.pImg.$el.clientHeight
    },
    onResize: debounce(function () {
      this.initImageDimensions()
    }, 200)
  },
  mounted() {
    this.hitLozad()
    this.initImageDimensions()
  },
  setup() {
    const goTo = useGoTo()
    return { goTo }
  },
  watch: {
    async product() {
      await this.$nextTick()
      window.elb('product visible', this.$el)
    }
  }
}
</script>

<style scoped>
#reason-why-one[loading='true'] {
  min-height: 5rem;
}

a#manufacturer[loading='true'] {
  min-width: 20%;
  min-height: 1.3rem;
}

h1 {
  line-height: var(--xxxl-font-size);
}

h1[loading='true'] {
  min-width: 70%;
  min-height: 1.5rem;
}

.award-list {
  top: 0;
  left: 0;
}

.manufacturer-name {
  font-size: var(--m-font-size);
  font-family: var(--area-font-family);
  letter-spacing: 1px;
  font-weight: 700;
}

.image-wrapper {
  height: 360px;
}

#product-stage {
  min-height: 600px;
}

.display-lg-and-up .image-wrapper {
  height: auto;
}

.black-bg-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity var(--transition-duration-l) var(--transition-timing-ease);
}

:deep(.banderole-recommendation svg) {
  display: none;
}

.display-sm-and-up :deep(.banderole-recommendation svg) {
  display: flex;
}

:deep(.mdc-select) {
  min-width: 155px;
}

:deep(.final-price) {
  font-size: var(--xxxl-font-size);
}

:deep(.price-per-unit) {
  font-size: var(--s-font-size);
}

.wishlist-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.high-contrast {
  filter: contrast(1.5);
}
</style>
