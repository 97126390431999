import RequestHelper from '@/services/helpers/RequestHelper'

const OPC_REGEX = /-opc-p\d+-c\d+/

class ApiController {
  constructor() {
    if (ApiController.Singleton) return

    ApiController.Singleton = this
  }

  async fetchProduct(productId, campaignId, config) {
    const { UtilityHelper, ProductSchema, API_URL } = await _getModules()

    // product_url needed to make the redirects work
    const appendix =
      config + `&product_url=${encodeURIComponent(location.pathname)}`

    const url = UtilityHelper.formatString(
      API_URL.CATALOG.PRODUCT.GET_V2,
      productId,
      campaignId,
      appendix
    )
    return _basicFetch(url, ProductSchema)
  }

  async fetchProductForDefaultCampaign(productId) {
    const { UtilityHelper, ProductSchema, API_URL } = await _getModules()

    const url = UtilityHelper.formatString(
      API_URL.CATALOG.PRODUCT.GET_V2_WITHOUT_CAMPAIGN,
      productId
    )
    return _basicFetch(url, ProductSchema)
  }

  async fetchApiV1Product(productId, campaignId, config) {
    const { UtilityHelper, API_URL } = await _getModules()

    // product_url needed to make the redirects work
    const appendix =
      config + `&product_url=${encodeURIComponent(location.pathname)}`

    const url = UtilityHelper.formatString(
      API_URL.CATALOG.PRODUCT.GET,
      productId,
      campaignId,
      appendix
    )
    return _basicFetch(url)
  }

  async fetchMetaProduct(productId, campaignId) {
    const {
      UtilityHelper,
      MetaProductSchema,
      PDP_REQUEST_URL: {
        META: { PRODUCT }
      }
    } = await _getModules()

    const url = UtilityHelper.formatString(PRODUCT, productId, campaignId)
    return _basicFetch(url, MetaProductSchema)
  }

  async fetchCustomerChunk(hash) {
    const { UtilityHelper, ChunkSchema, REQUEST_URL } = await _getModules()
    return _basicFetch(
      UtilityHelper.formatString(REQUEST_URL.META.CUSTOMER_PROFILE, hash),
      ChunkSchema
    )
  }

  async fetchCustomerFeatures() {
    const { API_URL } = await _getModules()
    return _basicFetch(API_URL.CUSTOMER.FEATURES)
  }

  async fetchAvailablePaymentMethods() {
    const { API_URL } = await _getModules()
    return _basicFetch(API_URL.CORE.AVAILABLE_PAYMENT_METHODS)
  }

  async fetchCustomerBraintreeToken() {
    const { API_URL } = await _getModules()
    return _basicFetch(API_URL.CUSTOMER.PAYMENT_METHODS.BRAINTREE_TOKEN)
  }

  async fetchCustomerReview(payload) {
    const {
      UtilityHelper,
      SchemaValidator,
      RequestHelper,
      CustomerReviewSchema,
      PDP_REQUEST_URL: {
        META: { CUSTOMER_REVIEWS }
      }
    } = await _getModules()

    const url = UtilityHelper.formatString(
      CUSTOMER_REVIEWS,
      payload.productId,
      payload.onlyWithText,
      payload.sort,
      payload.page
    )
    const res = await RequestHelper.sendGetRequest(url)
    if (res.data.length) {
      // validate only one entity
      SchemaValidator.validate(res.data[0], CustomerReviewSchema)
    }
    return res
  }

  async fetchManufacturer(manufacturerId, useV2 = false) {
    const { UtilityHelper, ManufacturerSchema, API_URL } = await _getModules()

    if (useV2)
      return _basicFetch(
        UtilityHelper.formatString(API_URL.MANUFACTURER.GET, manufacturerId)
      )

    return _basicFetch(
      UtilityHelper.formatString(
        API_URL.CATALOG.MANUFACTURER.GET,
        manufacturerId
      ),
      ManufacturerSchema
    )
  }

  async fetchCustomerProfile() {
    const { CustomerMetaSchema, REQUEST_URL } = await _getModules()

    const customerProfile = await _basicFetch(
      REQUEST_URL.META.CUSTOMER_ACCOUNT,
      CustomerMetaSchema
    )
    if (!customerProfile.customer_id) throw new Error('Invalid api key')
    return customerProfile
  }

  async fetchManufacturerProducts(id, search) {
    const {
      API_URL: {
        MANUFACTURER: { PRODUCTS }
      },
      UtilityHelper
    } = await _getModules()
    const sanitizedSearch =
      search.replace(/ & /g, '+%26+').replace(/;/g, '_') +
      '&pagination_limit=30'

    const url = UtilityHelper.formatString(PRODUCTS, id) + sanitizedSearch

    return RequestHelper.sendGetRequest(url)
  }

  async fetchCategory(search) {
    const {
      CATEGORY_PAGE_API_URL: { SEARCH_BY_CATEGORY_ID }
    } = await _getModules()
    // escape things for solr
    const sanitizedSearch = search.replace(/ & /g, '+%26+').replace(/;/g, '_')

    const url = SEARCH_BY_CATEGORY_ID + sanitizedSearch

    return _fetchCategory(url)
  }

  async fetchCategoryByIdentifier(identifier, search = '') {
    const {
      UtilityHelper,
      CATEGORY_PAGE_API_URL: { SEARCH_BY_IDENTIFIER }
    } = await _getModules()

    const url =
      UtilityHelper.formatString(SEARCH_BY_IDENTIFIER, identifier) + search

    return _fetchCategory(url)
  }

  async fetchCmsPage(pageId = null) {
    const { CmsPageSchema, CMS_PAGE_URL } = await _getModules()
    const url = CMS_PAGE_URL + (pageId ?? '')
    return _basicFetch(url, CmsPageSchema)
  }

  async fetchCmsBlock(id) {
    const {
      API_URL: {
        CMS: { BLOCK }
      },
      UtilityHelper
    } = await _getModules()
    return _basicFetch(UtilityHelper.formatString(BLOCK, id))
  }

  async fetchCmsVariable(...ids) {
    const { API_URL, UtilityHelper } = await _getModules()
    const url = UtilityHelper.formatString(
      API_URL.CMS.VARIABLE_BULK,
      ids.join()
    )
    return await _basicFetch(url)
  }

  async fetchProducts(url, showDisabled = false) {
    try {
      const data = await _basicFetch(url)
      return showDisabled
        ? data
        : data.filter((product) => product.is_enabled !== false)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async fetchItems(url) {
    try {
      return await RequestHelper.sendGetRequest(url)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async fetchRecommendations() {
    const { API_URL } = await _getModules()
    return _basicFetch(API_URL.RECOMMENDATIONS)
  }

  async fetchTurboPascalSort(categoryId, limit) {
    const { API_URL } = await _getModules()
    const url =
      API_URL.TURBO_PASCAL_SORT + `?category-id=${categoryId}&limit=${limit}`
    return _basicFetch(url)
  }

  async refreshCustomerToken() {
    const { RequestHelper, API_URL } = await _getModules()

    await RequestHelper.sendPostRequest(API_URL.AUTH.REFRESH)
  }

  async logoutCustomer() {
    const { RequestHelper, API_URL } = await _getModules()

    await RequestHelper.sendPostRequest(API_URL.AUTH.LOGOUT)
  }

  async loginCustomer({ email, password }) {
    const {
      RequestHelper,
      API_URL: {
        AUTH: { LOGIN, LOGIN_LEGACY }
      }
    } = await _getModules()
    const [
      {
        data: { access_token: accessToken, refresh_token: refreshToken }
      }
    ] = await Promise.all([
      await RequestHelper.sendPostRequest(LOGIN, { email, password }),
      await RequestHelper.sendPostRequest(LOGIN_LEGACY, { email, password })
    ])
    return { accessToken, refreshToken }
  }

  async verifyTwoFactorAuth(data) {
    const {
      RequestHelper,
      API_URL: {
        AUTH: { TWO_FACTOR_AUTH }
      }
    } = await _getModules()
    await RequestHelper.sendPostRequest(TWO_FACTOR_AUTH, data)
  }

  async resetPassword(email) {
    const {
      RequestHelper,
      API_URL: {
        AUTH: { RESET }
      }
    } = await _getModules()
    await RequestHelper.sendPostRequest(RESET, { email })
  }

  async loginCustomerWithApiKey() {
    const {
      RequestHelper,
      API_URL: {
        AUTH: { LOGIN_LEGACY }
      }
    } = await _getModules()
    await RequestHelper.sendPostRequest(LOGIN_LEGACY, {})
  }

  async registerCustomer({
    email,
    firstName,
    lastName,
    gender,
    title,
    password,
    dob
  }) {
    const {
      TrackingHelper,
      RequestHelper,
      API_URL: {
        AUTH: { REGISTER }
      }
    } = await _getModules()
    const utm = await TrackingHelper.getUtmParams()

    await RequestHelper.sendPostRequest(REGISTER, {
      email,
      first_name: firstName,
      last_name: lastName,
      gender,
      title,
      password,
      utm,
      dob
    })
  }

  async fetchUpsell(cartId) {
    const {
      UtilityHelper,
      CART_PAGE_URL: { UPSELL }
    } = await _getModules()
    const url = UtilityHelper.formatString(UPSELL, cartId)
    return _basicFetch(url)
  }

  async savePaymentMethod(payload) {
    const { RequestHelper, API_URL } = await _getModules()
    return RequestHelper.sendPostRequest(
      API_URL.CUSTOMER.PAYMENT_METHODS.ADD,
      payload
    )
  }

  async fetchBraintreeMethodNonce(payload) {
    const { RequestHelper, API_URL } = await _getModules()
    return RequestHelper.sendPostRequest(
      API_URL.CUSTOMER.PAYMENT_METHODS.BRAINTREE_METHOD_NONCE,
      payload
    )
  }

  async refreshWishList(uuid = null, page = 1, type = 'in-stock') {
    const {
      RequestHelper,
      API_URL: {
        CUSTOMER: { WISHLIST }
      }
    } = await _getModules()
    const url = uuid ? `${WISHLIST}/${uuid}` : WISHLIST
    return await RequestHelper.sendGetRequest(
      url + `?type=${type}&page_id=${page}`
    )
  }

  async addToWishList(payload) {
    const {
      RequestHelper,
      API_URL: {
        CUSTOMER: { WISHLIST }
      }
    } = await _getModules()
    const { data } = await RequestHelper.sendPostRequest(WISHLIST, payload)
    return data
  }

  async removeFromWishList(itemId, uuid) {
    const {
      RequestHelper,
      API_URL: {
        CUSTOMER: { WISHLIST }
      }
    } = await _getModules()
    const url = `${WISHLIST}/${uuid}/${itemId}`
    await RequestHelper.sendDeleteRequest(url)
  }

  async addItemToCart(
    cartId,
    payload,
    couponCode = null,
    applyCredits = false
  ) {
    const {
      TrackingHelper,
      RequestHelper,
      UtilityHelper,
      API_URL: {
        CART: { ITEMS_V2 }
      }
    } = await _getModules()

    const url = new URL(UtilityHelper.formatString(ITEMS_V2, cartId))
    if (couponCode) url.searchParams.append('apply_coupon_code', couponCode)
    if (applyCredits) url.searchParams.append('apply_credits', 'true')

    const { data } = await RequestHelper.sendPostRequest(url.toString(), {
      ...payload,
      utm: await TrackingHelper.getUtmParams()
    })

    return data
  }

  async updateCartItem(
    cartId,
    { cartItemId, ...payload },
    couponCode = null,
    applyCredits = false
  ) {
    const {
      RequestHelper,
      UtilityHelper,
      API_URL: {
        CART: { ITEM_V2 }
      }
    } = await _getModules()

    const url = new URL(UtilityHelper.formatString(ITEM_V2, cartId, cartItemId))
    if (couponCode) url.searchParams.append('apply_coupon_code', couponCode)
    if (applyCredits) url.searchParams.append('apply_credits', 'true')

    const { data } = await RequestHelper.sendPatchRequest(
      url.toString(),
      payload
    )
    return data
  }

  async removeCartItem(
    cartId,
    cartItemId,
    couponCode = null,
    applyCredits = false
  ) {
    const {
      RequestHelper,
      UtilityHelper,
      API_URL: {
        CART: { ITEM_V2 }
      }
    } = await _getModules()

    const url = new URL(UtilityHelper.formatString(ITEM_V2, cartId, cartItemId))
    if (couponCode) url.searchParams.append('apply_coupon_code', couponCode)
    if (applyCredits) url.searchParams.append('apply_credits', 'true')

    const { data } = await RequestHelper.sendDeleteRequest(url.toString())
    return data
  }

  async refreshCart(cartId, couponCode = null, applyCredits = false) {
    const {
      RequestHelper,
      UtilityHelper,
      API_URL: {
        CART: { GET_V2 }
      }
    } = await _getModules()

    const baseUrl = UtilityHelper.formatString(GET_V2, cartId)

    const searchParams = new URLSearchParams(window.location.search)
    const cartRuleId = searchParams.get('cart_rule_id')

    const url = new URL(baseUrl)
    if (cartRuleId) url.searchParams.append('cart_rule_id', cartRuleId)
    if (couponCode) url.searchParams.append('apply_coupon_code', couponCode)
    if (applyCredits) url.searchParams.append('apply_credits', 'true')

    const { data } = await RequestHelper.sendGetRequest(url.toString())
    return data
  }

  async placeOrder(payload, params) {
    const {
      TrackingHelper,
      RequestHelper,
      API_URL: {
        ORDERS: { PLACE }
      }
    } = await _getModules()
    const {
      data: { redirect_url: redirectUrl }
    } = await RequestHelper.sendPostRequest(PLACE, {
      ...payload,
      utm: await TrackingHelper.getUtmParams(params)
    })

    if (!redirectUrl) throw new Error('No redirect url is provided')
    return { redirectUrl }
  }

  async placeExpressOrder(payload) {
    const {
      RequestHelper,
      API_URL: {
        EXPRESS_ORDERS: { PLACE }
      }
    } = await _getModules()
    const {
      data: { redirect_url: redirectUrl }
    } = await RequestHelper.sendPostRequest(PLACE, { ...payload })

    if (!redirectUrl) throw new Error('No redirect url is provided')
    return { redirectUrl }
  }

  async subscribeNewsletter(payload = {}) {
    const {
      RequestHelper,
      API_URL: {
        CUSTOMER: { SUBSCRIPTIONS }
      }
    } = await _getModules()

    await RequestHelper.sendPostRequest(SUBSCRIPTIONS, payload)
  }

  async logPaymentError(paymentMethod, error) {
    const {
      RequestHelper,
      API_URL: {
        LOG: { PAYMENT_ERROR }
      }
    } = await _getModules()

    const payload = {
      payment_method: paymentMethod,
      message: error.message,
      code: 0,
      response_data: error?.details?.originalError ?? {}
    }
    await RequestHelper.sendPostRequest(PAYMENT_ERROR, payload)
  }

  async fetchCustomerAddresses() {
    const {
      AddressSchema,
      API_URL: {
        CUSTOMER: { ADDRESSES }
      }
    } = await _getModules()

    return _basicFetch(ADDRESSES, AddressSchema)
  }

  async fetchNavigation() {
    const {
      NavigationSchema,
      API_URL: {
        CMS: { NAVIGATION }
      }
    } = await _getModules()

    return _basicFetch(NAVIGATION, NavigationSchema)
  }

  async saveCustomerAddress(payload) {
    const {
      RequestHelper,
      SchemaValidator,
      AddressSchema,
      API_URL: {
        CUSTOMER: { ADDRESSES }
      }
    } = await _getModules()

    const { data } = await RequestHelper.sendPostRequest(ADDRESSES, payload)
    SchemaValidator.validate(data, AddressSchema)
    return data
  }

  async sendUtmParams(params) {
    const {
      RequestHelper,
      REQUEST_URL: {
        META: { CUSTOMER_UTM }
      }
    } = await _getModules()

    await RequestHelper.sendPostRequest(CUSTOMER_UTM, params)
  }

  async sendEventToDataMining(data) {
    const {
      RequestHelper,
      API_URL: {
        DATA_MINING: { EVENTS }
      }
    } = await _getModules()
    await RequestHelper.sendPostRequest(EVENTS, { name: data.event, data })
  }

  async sendProductToDataMining(data) {
    const {
      RequestHelper,
      API_URL: {
        DATA_MINING: { PRODUCT_VIEW }
      }
    } = await _getModules()

    await RequestHelper.sendPostRequest(PRODUCT_VIEW, data)
  }

  async sendCategoryToDataMining(data) {
    const {
      RequestHelper,
      API_URL: {
        DATA_MINING: { CATEGORY_VIEW }
      }
    } = await _getModules()

    await RequestHelper.sendPostRequest(CATEGORY_VIEW, data)
  }

  async sendCmsPageToDataMining(data) {
    const {
      RequestHelper,
      API_URL: {
        DATA_MINING: { PAGE_VIEW }
      }
    } = await _getModules()

    await RequestHelper.sendPostRequest(PAGE_VIEW, data)
  }

  async sendReviewVote(review, vote) {
    const {
      RequestHelper,
      REQUEST_URL: {
        REVIEW: { VOTE }
      }
    } = await _getModules()

    await RequestHelper.sendPostRequest(VOTE, { review, vote })
  }

  async saveReview(payload) {
    const {
      RequestHelper,
      API_URL: {
        CATALOG: {
          REVIEW: { ADD }
        }
      }
    } = await _getModules()

    await RequestHelper.sendPostRequest(ADD, payload)
  }

  // eslint-disable-next-line no-unused-vars
  async sendNpsVote(score, episode = 'browse') {
    // const { RequestHelper, API_URL: { NPS: { VOTE } } } = await _getModules()
    // const payload = { score, episode, origin: location.href }
    //
    // const { data: { activity_id: id } } = await RequestHelper.sendPostRequest(VOTE, payload)
    return 1
  }

  // eslint-disable-next-line no-unused-vars
  async sendNpsAnswer(id, answer) {
    // const { RequestHelper, API_URL: { NPS: { ANSWER } } } = await _getModules()
    // const payload = { activity_id:id, answer }
    //
    // await RequestHelper.sendPostRequest(ANSWER, payload)
  }

  async sendCouponCode(couponCode) {
    const {
      RequestHelper,
      REQUEST_URL: {
        META: { CHECKOUT_COUPON }
      }
    } = await _getModules()

    await RequestHelper.sendPostRequest(CHECKOUT_COUPON, { coupon: couponCode })
  }
}

const _basicFetch = async (url, schema = null) => {
  const { RequestHelper, SchemaValidator } = await _getModules()

  try {
    const { data } = await RequestHelper.sendGetRequest(url)
    schema && SchemaValidator.validate(data, schema)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const _fetchCategory = async (url) => {
  const { RequestHelper, SchemaValidator, CategorySchema } = await _getModules()

  try {
    const res = await RequestHelper.sendGetRequest(url)
    SchemaValidator.validate(res.data, CategorySchema)
    return res
  } catch (e) {
    return Promise.reject(e)
  }
}

const _isOpc = () => OPC_REGEX.test(location.pathname)

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { API_URL, REQUEST_URL },
      { CATEGORY_PAGE_API_URL },
      { CART_PAGE_URL },
      { PDP_REQUEST_URL },
      { CMS_PAGE_URL },
      { default: ChunkSchema },
      { default: CustomerReviewSchema },
      { default: AddressSchema },
      { default: ManufacturerSchema },
      { default: CustomerMetaSchema },
      { default: CategorySchema },
      { default: ProductSchema },
      { default: MetaProductSchema },
      { default: CmsPageSchema },
      { default: RequestHelper },
      { default: UtilityHelper },
      { default: SchemaValidator },
      { default: TrackingHelper }
    ] = await Promise.all([
      await import('@/constants/GlobalConstants'),
      await import('@/constants/CategoryPageConstants'),
      await import('@/constants/CartPageConstants'),
      await import('@/constants/PdpConstants'),
      await import('@/constants/CmsPageConstants'),
      await import('@/modules/schemas/customer/ChunkSchema'),
      await import('@/modules/schemas/CustomerReviewSchema'),
      await import('@/modules/schemas/customer/AddressSchema'),
      await import('@/modules/schemas/ManufacturerSchema'),
      await import('@/modules/schemas/customer/CustomerMetaSchema'),
      await import('@/modules/schemas/CategorySchema'),
      await import('@/modules/schemas/product/ProductSchema'),
      await import('@/modules/schemas/product/MetaProductSchema'),
      await import('@/modules/schemas/CmsPageSchema'),
      await import('@/services/helpers/RequestHelper'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/modules/schemas/SchemaValidator'),
      await import('@/services/helpers/TrackingHelper')
    ])
    cache = {
      API_URL,
      REQUEST_URL,
      CATEGORY_PAGE_API_URL,
      PDP_REQUEST_URL,
      CMS_PAGE_URL,
      CART_PAGE_URL,
      ChunkSchema,
      CustomerReviewSchema,
      AddressSchema,
      ManufacturerSchema,
      CustomerMetaSchema,
      CategorySchema,
      ProductSchema,
      MetaProductSchema,
      CmsPageSchema,
      RequestHelper,
      UtilityHelper,
      SchemaValidator,
      TrackingHelper
    }
    return cache
  }
})()

export default new ApiController()
