<script setup>
import UspBar from '@/components/basic/atoms/UspBar.vue'
import TheHeader from '@/components/basic/organisms/TheHeader.vue'
import TheWishlistTemplate from '@/components/wishlist/templates/TheWishlistTemplate.vue'
import { usePage } from '@/composables/usePage.js'
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, onBeforeMount, ref } from 'vue'
const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const AppBanner = defineAsyncComponent(
  () => import('@/components/basic/molecules/AppBanner.vue')
)
const TheFooter = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheFooter.vue')
)
const TheRecentlyViewedItems = defineAsyncComponent(
  () => import('@/components/basic/molecules/TheRecentlyViewedItems.vue')
)
const MultiTools = defineAsyncComponent(
  () => import('@/components/basic/molecules/MultiTools.vue')
)
const Tool = defineAsyncComponent(
  () => import('@/components/basic/atoms/Tool.vue')
)

const { customer, meta, unHead } = usePage()
const { customerIsAdmin } = storeToRefs(customer)

const adminTool = ref(null)
onBeforeMount(async () => {
  const { TYPES } = await import('@/constants/AdminTool')
  adminTool.value = TYPES
})

meta.title = 'new_cart_page_wishlist'
meta.description = 'fallback_meta_description'
meta.translateValues = true
unHead()
</script>

<template>
  <div id="content" data-elbglobals="page_type:wishlist">
    <AppBanner />
    <UspBar />
    <TheHeader />
    <TheWishlistTemplate />
    <LazyComponent id="recent-items" :delay="1000">
      <TheRecentlyViewedItems class="pt-5" />
    </LazyComponent>
    <LazyComponent id="footer" :delay="1500">
      <TheFooter />
    </LazyComponent>
    <MultiTools id="fixed-tools" v-if="customerIsAdmin">
      <Tool :type="adminTool.TRANSLATE_MODE" />
      <Tool :type="adminTool.HIGHLIGHT_CMS_ITEMS" />
      <Tool :type="adminTool.TOGGLE_DATALAYER_LOGGING" />
      <Tool :type="adminTool.TOGGLE_ELBWALKER_LOGGING" />
      <Tool :type="adminTool.TOGGLE_HIGH_CONTRAST" />
    </MultiTools>
  </div>
</template>
