<template>
  <div
    id="content"
    itemscope
    itemtype="https://schema.org/Product"
    data-elbglobals="page_type:product_detail"
  >
    <AppBanner />
    <AlertBanner
      v-if="!calculated.isVisible"
      :text="translate('alert_message_preview_mode')"
    />
    <UspBar />
    <TheHeader />
    <main id="page-template">
      <ThePdpTemplate />
    </main>
    <LazyComponent id="footer" :delay="1500">
      <TheFooter />
    </LazyComponent>
  </div>
</template>

<script>
import UspBar from '@/components/basic/atoms/UspBar.vue'
import TheHeader from '@/components/basic/organisms/TheHeader.vue'
import ThePdpTemplate from '@/components/pdp/templates/ThePdpTemplate.vue'
import {
  containsPrivateInfo,
  NO_INDEX_FOLLOW
} from '@/services/helpers/MetaHelper.js'
import { usePdpStore } from '@/stores/pdp'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const AppBanner = defineAsyncComponent(
  () => import('@/components/basic/molecules/AppBanner.vue')
)
const AlertBanner = defineAsyncComponent(
  () => import('@/components/basic/atoms/AlertBanner.vue')
)
const TheFooter = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheFooter.vue')
)
const OPEN_MOBILE_APP_PARAM = 'vic_app'

export default {
  head() {
    return {
      title: this.title,
      meta: [
        {
          name: 'robots',
          content: containsPrivateInfo()
            ? NO_INDEX_FOLLOW
            : this.product?.robots
        },
        { name: 'description', content: this.description }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.product?.canonical_for
        },
        ...this.alternates
      ]
    }
  },
  components: {
    LazyComponent,
    AppBanner,
    AlertBanner,
    TheHeader,
    ThePdpTemplate,
    TheFooter,
    UspBar
  },
  computed: {
    ...mapState(usePdpStore, {
      product: 'getApiProduct',
      productUpdating: 'isUpdating',
      calculated: 'getCalculated'
    }),
    title() {
      const manufacturer = this.product?.manufacturer_name ?? ''
      const productName = this.product?.name ?? ''

      return `${manufacturer} ${productName}`.trim()
    },
    description() {
      return (
        this.product?.meta_description ??
        this.translate('fallback_meta_description')
      )
    },
    alternates() {
      const alternates =
        this.product?.alternates?.map((alternate) => ({
          rel: 'alternate',
          hreflang: alternate.hreflang,
          href: alternate.href
        })) || []
      return alternates
    }
  },
  methods: {
    ...mapActions(usePdpStore, ['refreshProduct']),
    async routeChangeHandler(productId, campaignId) {
      await this.refreshProduct({ productId, campaignId })
      void this.productChangeHandler()
      void this.openMobileAppHandler({ productId, campaignId })
    },
    trackViewItem() {
      setTimeout(async () => {
        const [{ default: GA4Controller }, { default: AnalyticsItemFactory }] =
          await Promise.all([
            await import('@/services/analytics/GA4Controller'),
            await import('@/modules/tag_manager/AnalyticsItemFactory')
          ])
        void GA4Controller.pushViewItemEvent({
          item: AnalyticsItemFactory.getAnalyticsItemFromApiProduct(
            this.product
          )
        })
        void GA4Controller.pushChangePageEvent()
      }, 0)
    },
    async openMobileAppHandler({ productId, campaignId }) {
      const { default: NavigationHelper } = await import(
        '@/services/helpers/NavigationHelper'
      )
      if (
        this.$router.currentRoute.value.query[OPEN_MOBILE_APP_PARAM] === 'true'
      ) {
        NavigationHelper.openMobileAppPdp({ productId, campaignId })
      }
    },
    async productChangeHandler() {
      this.trackViewItem()
      const [{ default: DomHelper }] = await Promise.all([
        await import('@/services/helpers/DomHelper')
      ])
      if (!this.$route.hash) {
        void DomHelper.scrollIntoView('content')
      }
    }
  },
  beforeRouteEnter({ params: { pId, cId } }, _, next) {
    next((vm) => {
      vm.routeChangeHandler(pId, cId)
    })
  },
  beforeRouteUpdate({ params: { pId, cId } }, _, next) {
    this.routeChangeHandler(pId, cId)
    next()
  }
}
</script>
<style scoped>
.fade-enter-active {
  transition: opacity 1s var(--transition-timing-cb-vicampo) 0.5s;
}

.fade-leave-active {
  transition: opacity 0.5s var(--transition-timing-cb-vicampo);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#main-footer {
  margin-bottom: 120px;
  @media screen and (min-width: 329px) {
    margin-bottom: 100px;
  }
}

.display-lg-and-up #main-footer {
  margin-bottom: 0;
}
</style>
