<template>
  <SvgWrapper class="f-graphit rotate" use="icon_svg_down" dimensions="18" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const SvgWrapper = defineAsyncComponent(
  () => import('@/components/basic/atoms/SvgWrapper.vue')
)

export default {
  components: { SvgWrapper },
  props: {
    rotate: {
      type: String,
      default: '0'
    }
  },
  computed: {
    rotateStyle() {
      return `rotate(${this.rotate}deg)`
    }
  }
}
</script>

<style scoped>
.rotate {
  transform: v-bind(rotateStyle);
}
</style>
