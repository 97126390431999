<template>
  <VContainer id="filter" class="mt-2" item-list-id="filter">
    <div class="v-row">
      <div class="v-col-12 v-col-lg-3" v-if="$vuetify.display.lgAndUp">
        <TheFilterSideBar />
      </div>
      <div class="v-col-12 v-col-lg-9">
        <div class="v-row sticky-top bg-light-beige z-3">
          <div class="v-col-12">
            <TheFilterTopBar
              class="filter-bar mb-2"
              v-if="$vuetify.display.lgAndUp"
              :index-from="indexFrom"
              :index-to="indexTo"
            />
            <TheFilterMobile v-else />
            <div
              v-if="!error && activeAttributes?.length > 0"
              class="attribute-options d-flex pl-3 pl-md-0 pb-3 pb-md-1"
            >
              <TheFilterAppliedOptionsBar />
            </div>
          </div>
        </div>
        <transition name="fade">
          <HeroTile
            v-if="heroData.product_id && heroData.campaign_id"
            v-show="!loading"
            :product-id="heroData.product_id"
            :campaign-id="heroData.campaign_id"
            :catch-phrase="heroData.catch_phrase"
            class="mt-3"
          />
        </transition>
        <LoadingOverlay
          width="70"
          height="70"
          :use-background="false"
          class="position-relative my-3"
          v-show="loading && !error"
        />
        <div v-if="hasResults">
          <transition name="fade">
            <div id="product-list" v-if="totalPages" v-show="!loading">
              <TheFilterProductList
                :offers-visible="totalPages === currentPage"
                @changeSupplier="onPageChange(1)"
              />
            </div>
          </transition>
          <VSheet
            v-if="totalPages > 1"
            :loading="loading"
            class="d-flex align-center justify-center py-3"
          >
            <VPagination
              v-model="currentPage"
              :length="totalPages"
              @update:modelValue="changePage"
              :total-visible="$vuetify.display.smAndUp ? 5 : 1"
            />
          </VSheet>
        </div>
        <div v-else-if="!loading">
          <transition name="fade">
            <NoResults class="mt-md-3" />
          </transition>
        </div>
      </div>
    </div>
  </VContainer>
</template>

<script>
import { PRODUCTS_PER_PAGE } from '@/constants/CategoryPageConstants'
import { useSearchConfig } from '@/stores/searchConfig'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'
import { useGoTo } from 'vuetify'

const LoadingOverlay = defineAsyncComponent(
  () => import('@/components/basic/atoms/LoadingOverlay.vue')
)
const TheFilterAppliedOptionsBar = defineAsyncComponent(
  () => import('@/components/category/molecules/TheFilterAppliedOptionsBar.vue')
)
const TheFilterProductList = defineAsyncComponent(
  () => import('@/components/category/molecules/TheFilterProductList.vue')
)
const TheFilterSideBar = defineAsyncComponent(
  () => import('@/components/category/organisms/TheFilterSideBar.vue')
)
const TheFilterTopBar = defineAsyncComponent(
  () => import('@/components/category/molecules/TheFilterTopBar.vue')
)
const TheFilterMobile = defineAsyncComponent(
  () => import('@/components/category/organisms/TheFilterMobile.vue')
)
const NoResults = defineAsyncComponent(
  () => import('@/components/category/molecules/NoResults.vue')
)
const HeroTile = defineAsyncComponent(
  () => import('@/components/category/molecules/HeroTile.vue')
)

const SUPPLIER_VICAMPO = 'VICAMPO'
const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ default: MessageHelper }] = await Promise.all([
      await import('@/services/helpers/MessageHelper')
    ])
    cache = { MessageHelper }
    return cache
  }
})()

export default {
  components: {
    NoResults,
    LoadingOverlay,
    TheFilterMobile,
    TheFilterSideBar,
    TheFilterProductList,
    TheFilterAppliedOptionsBar,
    HeroTile,
    TheFilterTopBar
  },
  data() {
    return {
      currentPage: 1
    }
  },
  computed: {
    ...mapState(useSearchConfig, {
      activeAttributes: 'activeAttributes',
      loading: 'categoryIsLoading',
      category: 'category',
      config: 'getConfig',
      products: 'getProducts'
    }),
    error() {
      return this.config.error
    },
    hasSupplierVicampo() {
      const { supplier } = this.config.filters
      if (!supplier) return true
      return Object.keys(supplier).includes(SUPPLIER_VICAMPO)
    },
    heroData() {
      const { supplier: _, ...rest } = this.config.filters
      const { hero_data: data } = this.category

      if (this.isSearch) {
        return {}
      }

      if (
        !this.hasSupplierVicampo ||
        Object.keys(rest).length ||
        !(data?.product_id && data?.campaign_id)
      ) {
        return {}
      }

      return {
        product_id: data.product_id,
        campaign_id: data.campaign_id,
        catch_phrase: data.catch_phrase
      }
    },
    totalPages() {
      return this.config.totalPages
    },
    indexFrom() {
      if (this.indexTo > PRODUCTS_PER_PAGE) {
        return (this.currentPage - 1) * PRODUCTS_PER_PAGE + 1
      }
      return this.indexTo ? 1 : 0
    },
    indexTo() {
      return Math.min(
        this.currentPage * PRODUCTS_PER_PAGE,
        this.config.totalCount
      )
    },
    hasResults() {
      return this.config.totalCount > 0
    },
    searchTerm() {
      return this.config.query?.original
    },
    hasActiveAttributes() {
      return (
        Object.keys(this.config.filters || {}).length > 0 || !!this.searchTerm
      )
    },
    isSearch() {
      return !!this.$route.query.search
    }
  },
  methods: {
    ...mapActions(useSearchConfig, [
      'fetchCategory',
      'updateCategory',
      'applyFilter'
    ]),
    async errorCallback(e) {
      console.error(e)
      const { MessageHelper } = await _getModules()
      await MessageHelper.addErrorMessage('unknown_error')
    },
    async onPageChange(value) {
      try {
        await this.changePage(value)
        await this.handleUpdate()
      } catch (e) {
        await this.errorCallback(e)
      }
    },
    async handleUpdate() {
      await this.fetchCategory()
    },
    async changePage(value) {
      this.updateCategory({ group: 'pagination_page', value, immediate: false })
      this.currentPage = value
      this.applyFilter()
      this.goTo('#filter')
    }
  },
  setup() {
    const goTo = useGoTo()
    return { goTo }
  },
  async created() {
    this.currentPage = +this.$route.query.pagination_page || 1
  }
}
</script>
<style scoped>
.attribute-options {
  min-height: 60px;
  overflow-x: scroll;
  flex-wrap: nowrap;
  margin: 0 -15px;
}

.display-md-and-up .attribute-options {
  overflow-x: initial;
  flex-wrap: wrap;
  margin: 0;
}

.loading {
  min-height: 70px;
}

.fade-enter-active {
  transition: opacity 1s var(--transition-timing-cb-vicampo) 0.5s;
}

.fade-leave-active {
  transition: opacity 0.5s var(--transition-timing-cb-vicampo);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
