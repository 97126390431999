import { adminGuard, customerGuard } from '@/router/guards'
import { createRouter, createWebHistory } from 'vue-router'

import TheCartPage from '@/views/TheCartPage.vue'
import TheCategoryPage from '@/views/TheCategoryPage.vue'
import TheCheckoutPage from '@/views/TheCheckoutPage.vue'
import TheCmsPage from '@/views/TheCmsPage.vue'
import TheErrorPage from '@/views/TheErrorPage.vue'
import TheManufacturerPage from '@/views/TheManufacturerPage.vue'
import TheOpcPage from '@/views/TheOpcPage.vue'
import TheProductDetailPage from '@/views/TheProductDetailPage.vue'
import TheSearchPage from '@/views/TheSearchPage.vue'
import TheWishListPage from '@/views/TheWishListPage.vue'

const GTM = {
  cart: {
    modulePath: 'checkout/cart',
    pageCategory: '',
    applicationPageName: 'cart'
  },
  cms: {
    modulePath: 'cms/page/view',
    pageCategory: '',
    applicationPageName: 'cms'
  },
  category: {
    modulePath: 'catalog/category/list',
    pageCategory: '',
    applicationPageName: 'category'
  },
  search: {
    modulePath: 'search/index/index',
    pageCategory: '',
    applicationPageName: 'category'
  },
  pdp: {
    modulePath: 'catalog/product/view',
    pageCategory: '',
    pdpCategory: 'fe-app-pdp',
    applicationPageName: 'pdp'
  },
  opc: {
    modulePath: 'opc/index',
    pageCategory: '',
    applicationPageName: 'opc'
  },
  wishlist: {
    modulePath: 'wishlist/index',
    pageCategory: '',
    applicationPageName: 'wishlist'
  },
  home: {
    modulePath: 'cms/page/view',
    pageCategory: '',
    applicationPageName: 'cms',
    homepage: true
  }
}

const routes = [
  // static routes
  { path: '/', name: 'home', component: TheCmsPage },
  {
    path: '/error',
    name: 'error',
    component: TheErrorPage
  },
  {
    path: '/checkout/cart',
    name: 'cart',
    component: TheCartPage
  },
  {
    path: '/search',
    name: 'search',
    component: TheSearchPage
  },
  {
    path: '/merkliste',
    name: 'wishlist',
    component: TheWishListPage,
    beforeEnter: customerGuard
  },
  // dynamic routes
  {
    path: '/:prefix*-pdp-p:pId(\\d+)-c:cId(\\d+):suffix?',
    name: 'pdp',
    component: TheProductDetailPage
  },
  {
    path: '/:prefix*-opc-p:pId(\\d+)-c:cId(\\d+):suffix?',
    name: 'opc',
    component: TheOpcPage
  },
  {
    path: '/:prefix*-man-m:id(\\d+):suffix?',
    name: 'manufacturer',
    component: TheManufacturerPage
  },
  {
    path: '/:prefix*-cat-c:id(\\d+):suffix?',
    name: 'category',
    component: TheCategoryPage
  },
  {
    path: '/:prefix*-cms-p:id(\\d+):suffix?',
    name: 'cms',
    component: TheCmsPage
  },
  {
    path: '/checkout/:step',
    props: true,
    name: 'checkout',
    component: TheCheckoutPage,
    beforeEnter: adminGuard
  },
  { path: '/:catchAll(.*)', name: 'fallback', component: TheErrorPage }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(
  async ({ path: to, name: toName }, { path: from, name: fromName }, next) => {
    if (fromName && from === to) {
      next()
      return
    }

    if (typeof window === 'undefined') {
      console.warn('window is undefined')
      return
    }

    void _refreshData()
    void _setupDataLayer(toName)

    next()
  }
)

router.afterEach(async ({ path: from }, { path: to }) => {
  const { WALKER_COMMANDS, elb, DomHelper } = await _getModules()

  if (typeof window === 'undefined') {
    console.warn('window is undefined')
    return
  }

  elb(WALKER_COMMANDS.run)

  if (from === to) return

  DomHelper.scrollTo('#root')
})

const _refreshData = async () => {
  const { wishlist, cart, customer, lazyStore } = await _getModules()
  await customer.establishSession()
  await lazyStore.setForceLoad({ value: false })
  void cart.refreshCart()
  void wishlist.refreshWishlistItems()
  void wishlist.refreshWishlistSoldOutItems()
}

const _setupDataLayer = async (module) => {
  const { TagManager } = await _getModules()
  TagManager.pageUpdate(module, GTM[module])
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { useCustomerStore },
      { useWishlistStore },
      { useLazyStore },
      { useCartStore },
      { default: TagManager },
      { elb },
      { GLOBAL_STORE },
      { WALKER_COMMANDS },
      { default: DomHelper }
    ] = await Promise.all([
      await import('@/stores/customer'),
      await import('@/stores/wishlist'),
      await import('@/stores/lazyStore'),
      await import('@/stores/cart'),
      await import('@/services/analytics/TagManager'),
      await import('@elbwalker/walker.js'),
      await import('@/constants/GlobalConstants'),
      await import('@/services/helpers/ElbwalkerHelper'),
      await import('@/services/helpers/DomHelper')
    ])
    cache = {
      customer: useCustomerStore(),
      wishlist: useWishlistStore(),
      lazyStore: useLazyStore(),
      cart: useCartStore(),
      TagManager,
      elb,
      GLOBAL_STORE,
      WALKER_COMMANDS,
      DomHelper
    }
    return cache
  }
})()

export default router
