import { nextTick, reactive, ref, watch } from 'vue'

const useTracking = (el) => {
  const state = reactive({
    promotions: [],
    contents: []
  })
  const ga4Data = ref({})
  const kill = () => {
    state.promotions.forEach((e) => {
      e.destroy()
    })
    state.contents.forEach((e) => {
      e.destroy()
    })
  }

  const initPromotionAndContentTracking = async () => {
    kill()
    await nextTick()

    setTimeout(() => {
      // prevent failing on <!--v-if--> when there is no rendered content
      if (!el?.value.tagName) return

      _initPromotionTracking(el, ga4Data, state)
      _initContentTracking(el, ga4Data, state)
    }, 100)
  }

  watch(el, () => initPromotionAndContentTracking)
  watch(ga4Data, initPromotionAndContentTracking, { deep: true })

  return { ga4Data, kill, initPromotionAndContentTracking }
}

const _initPromotionTracking = async (el, data, state) => {
  const { Promotion } = await _getModules()
  state.promotions = _getNodes(el, data, Promotion)
}

const _initContentTracking = async (el, data, state) => {
  const { Content } = await _getModules()
  state.contents = _getNodes(el, data, Content)
}

const _getNodes = ({ value: el }, data, trackType) =>
  [...el.querySelectorAll(`[data-${trackType.getAttributeName()}]`)].map(
    (e) => new trackType(e, data)
  )

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ Promotion }, { Content }] = await Promise.all([
      import('@/services/analytics/Promotion'),
      import('@/services/analytics/Content')
    ])
    cache = { Promotion, Content }
    return cache
  }
})()

export { useTracking }
