const IMAGE_PREFIXES = {
  IMAGE_RETINA:
    'https://www.vicampo.de/media/cache/10000/image/x1400/proportional/x/4614b7f85b/',
  IMAGE:
    'https://www.vicampo.de/media/cache/10000/image/x700/proportional/x/1f469530b3/',
  SMALL_IMAGE_RETINA:
    'https://www.vicampo.de/media/cache/10000/small_image/400x/crop_bottom/400x520/353c25da18/',
  SMALL_IMAGE:
    'https://www.vicampo.de/media/cache/10000/small_image/200x/crop_bottom/200x260/a9b50a3e3a/'
}

export const BACKGROUNDS = {
  BUNDLE: {
    X: new URL('@/assets/images/pdp_bundle_stage_bg-min.jpg', import.meta.url)
      .href,
    XX: new URL(
      '@/assets/images/pdp_bundle_stage_bg-min-2x.jpg',
      import.meta.url
    ).href
  },
  SIMPLE: {
    X: new URL('@/assets/images/background_lg_xl-min.jpg', import.meta.url)
      .href,
    XX: new URL('@/assets/images/background_lg_xl-min-2x.jpg', import.meta.url)
      .href
  }
}

const BASE_REGEXP = /%s/
const STATUS_FORBIDDEN = 403

class UtilityHelper {
  static formatString(subject, ...data) {
    if (!subject) return ''

    if (BASE_REGEXP.test(subject))
      return data.reduce(
        (result, replace) => result.replace(BASE_REGEXP, replace),
        subject
      )

    return data.reduce(
      (result, replace, i) =>
        result.replace(new RegExp('\\{' + i + '\\}', 'gm'), replace),
      subject
    )
  }

  static getRandomHash() {
    return Math.random().toString(36).substring(2, 7)
  }

  /** Srcset attribute for picture tags */
  static generateSrcSet(imageSrc, retinaSrc) {
    imageSrc &&= `${imageSrc} 1x`
    retinaSrc &&= `${retinaSrc} 2x`

    // default to empty string if no value is set
    imageSrc ||= ''
    retinaSrc ||= ''

    return imageSrc && retinaSrc
      ? `${imageSrc}, ${retinaSrc}`
      : imageSrc + retinaSrc
  }

  /**
   * Prefixes the product images
   * Can be used when the picture url is relative
   *
   * @typedef {Object} ImageData
   * @property {string} src
   * @property {string} retina_src
   *
   * @returns {ImageData}
   */
  static prefixImage(imageSrc, useFullBottleImage = false) {
    // prevent console error because of invalid url
    if (!imageSrc) {
      return { src: '', retina_src: '' }
    }
    return useFullBottleImage
      ? {
          src: IMAGE_PREFIXES.IMAGE + imageSrc,
          retina_src: IMAGE_PREFIXES.IMAGE_RETINA + imageSrc
        }
      : {
          src: IMAGE_PREFIXES.SMALL_IMAGE + imageSrc,
          retina_src: IMAGE_PREFIXES.SMALL_IMAGE_RETINA + imageSrc
        }
  }

  static isSnakeCase(string) {
    if (!string) {
      return false
    }
    const regex = string.match(/[a-z|0-9]+(_[a-z|0-9]+)*/g)
    return regex && regex.join('').length === string.length
  }

  static stringElementsStartWithText(string, needle) {
    const lowerCaseString = string.toLowerCase()
    const lowerCaseNeedle = needle.toLowerCase()

    const parts = lowerCaseString
      .split(' ')
      .filter((part) => part.startsWith(lowerCaseNeedle))
    return parts.length > 0
  }

  static noticeNRError(error, messagePrefix = '') {
    setTimeout(() => {
      void _log(error, messagePrefix)
    }, 0)
  }

  static uuid() {
    if (window.crypto?.randomUUID) return window.crypto.randomUUID()

    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
  }
}

export const debounce = (func, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

export const throttle = (func, delay) => {
  let wait = false
  let waitingArgs
  const handler = () => {
    if (!waitingArgs) {
      wait = false
      return
    }
    func(...waitingArgs)
    waitingArgs = null
    setTimeout(handler, delay)
  }

  return (...args) => {
    if (wait) {
      waitingArgs = args
      return
    }

    func(...args)
    wait = true

    setTimeout(handler, delay)
  }
}

const _mediaQuery = (query) => matchMedia(query).matches

const _log = async (error, messagePrefix) => {
  if (!window.newrelic || error.status === STATUS_FORBIDDEN) return

  const { customerStore } = await _getModules()
  const extraData = { customer_id: customerStore.customerProfile.customer_id }

  error.message = `${messagePrefix} ${error.message}`
  window.newrelic.noticeError(error, extraData)
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ useCustomerStore }] = await Promise.all([
      await import('@/stores/customer')
    ])
    cache = { customerStore: useCustomerStore() }
    return cache
  }
})()

export default UtilityHelper
