import '@/assets/styles/fonts.css'
import { customIcons } from '@/plugins/vuetify/customIcons.js'
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components'
import 'vuetify/styles'
const BORDER_RADIUS = 'true'
const vicampoThemeRebrand = {
  dark: false,
  colors: {
    background: '#f4ece4',
    surface: '#FFFFFF',
    primary: '#8b2243',
    'light-primary': '#bb2654',
    'extra-light-primary': '#d06a89',
    secondary: '#222222',
    error: '#e81038',
    info: '#222222',
    success: '#4CAF50',
    warning: '#FB8C00',
    // Vicampo colors
    graphit: '#2D2D2D',
    winered: '#8b2243',
    ochre: '#dac1a7',
    'light-ochre': '#e8d9ca',
    beige: '#e2dcd1',
    'light-beige': '#f4ece4',
    lavendel: '#d6c7d6',
    'light-lavendel': '#e8d9e8',
    'extra-light-lavendel': '#dfe0f0',
    white: '#ffffff',
    black: '#000000',
    chalkboard: '#2d2e2f',
    'winered-dark': '#850d39',
    clay: '#918689',
    gray: '#666666',
    'light-gray': '#999999',
    'cement-gray': '#cccccc',
    'ash-gray': '#eeeeee',
    'dirty-white': '#f9f9f9',
    creme: '#f9f7f4',
    'light-creme': '#c5b8ac',
    'form-warning': '#ee5b5b',
    'mam-blue': '#0c2058',
    'great-gold': '#ebc03f',
    gold: '#ebc586',
    silver: '#96a4a5',
    // new additional colors:
    teal: '#3e4e82',
    'light-teal': '#a9b3d3',
    purple: '#682772',
    'light-purple': '#c29ac7',
    brown: '#964421',
    'light-brown': '#d59767',
    cyan: '#0090a5',
    'light-cyan': '#a0cecf',
    pink: '#d465a2',
    'light-pink': '#f9cfe3',
    orange: '#fe7a1d',
    'light-orange': '#fbc476',
    green: '#96aa24',
    'light-green': '#dde470',
    magenta: '#e92d66',
    'light-magenta': '#f7b8ba',
    yellow: '#fdd30d',
    'light-yellow': '#ffebb1'
  }
}

const vicampoThemeHighContrast = {
  dark: false,
  colors: {
    background: '#f0ede6',
    surface: '#FFFFFF',
    primary: '#99003f',
    secondary: '#000000',
    error: '#EE5B5B',
    info: '#000000',
    success: '#4CAF50',
    warning: '#FB8C00',
    // Vicampo colors
    graphit: '#000000',
    winered: '#99003f',
    ochre: '#ae8a79',
    beige: '#e2dcd1',
    'light-beige': '#f0ede6',
    white: '#ffffff',
    black: '#000000',
    chalkboard: '#000000',
    'winered-dark': '#850d39',
    clay: '#000000',
    gray: '#000000',
    'light-gray': '#000000',
    'cement-gray': '#000000',
    'ash-gray': '#FFFFFF',
    'dirty-white': '#FFFFFF',
    creme: '#FFFFFF',
    'light-ochre': '#c5b8ac',
    'form-warning': '#ee5b5b',
    'mam-blue': '#0c2058',
    'great-gold': '#ebc03f',
    gold: '#ebc586',
    silver: '#96a4a5'
  }
}

export const getComponentDefaults = () => ({
  VCheckbox: {
    trueIcon: 'custom:checkboxOn',
    falseIcon: 'custom:checkboxOff'
  },
  VRadioGroup: {
    trueIcon: 'custom:radioButtonOn',
    falseIcon: 'custom:radioButtonOff'
  },
  VRadio: {
    trueIcon: 'custom:radioButtonOn',
    falseIcon: 'custom:radioButtonOff'
  },
  VBtn: {
    color: 'primary',
    variant: 'flat',
    rounded: BORDER_RADIUS
  },
  VBtnSecondary: {
    color: 'secondary',
    variant: 'outlined',
    rounded: BORDER_RADIUS
  },
  VRow: {
    noGutters: BORDER_RADIUS
  },
  VCard: {
    rounded: BORDER_RADIUS,
    elevation: 1
  },
  VSelect: {
    height: '56px',
    menuIcon: 'custom:down',
    variant: 'outlined'
  },
  VTextField: {
    variant: 'outlined',
    rounded: BORDER_RADIUS
  },
  VTextarea: {
    variant: 'outlined',
    rounded: BORDER_RADIUS
  },
  VList: {
    rounded: BORDER_RADIUS
  },
  VExpansionPanels: {
    elevation: 0,
    rounded: BORDER_RADIUS,
    collapseIcon: 'custom:collapse',
    expandIcon: 'custom:expand'
  },
  VPagination: {
    variant: 'outlined',
    rounded: BORDER_RADIUS,
    prevIcon: 'custom:left',
    nextIcon: 'custom:right'
  },
  VDialog: {
    maxWidth: '1140px'
  },
  VSheet: {
    rounded: true,
    elevation: false,
    VSheet: {
      elevation: 2
    }
  }
})

const opt = {
  defaults: getComponentDefaults('vicampoThemeRebrand'),
  aliases: {
    VBtnSecondary: VBtn
  },
  theme: {
    defaultTheme: 'vicampoThemeRebrand',
    themes: {
      vicampoThemeHighContrast: vicampoThemeHighContrast,
      vicampoThemeRebrand: vicampoThemeRebrand
    }
  },
  icons: {
    sets: {
      custom: customIcons
    }
  }
}

const vuetify = createVuetify(opt)

export default vuetify
