import { useHead } from '@unhead/vue'

const useCss = (state) => {
  useHead({
    style: [
      {
        type: 'text/css',
        children: () => state.css
      }
    ]
  })
}

export { useCss }
