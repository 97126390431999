<template>
  <div id="content" :data-elbglobals="pageType">
    <TheJsonLd />
    <AppBanner v-if="!showBlankPage" />
    <AlertBanner
      v-if="showAppBanner"
      :text="translate('alert_message_preview_mode')"
    />
    <UspBar />
    <TheHeader v-if="!showBlankPage" />
    <main id="page-template">
      <TheCmsTemplate v-if="done" :page-id="cmsPageId" :key="cmsPageId" />
    </main>
    <LazyComponent id="footer" v-if="!showBlankPage" :delay="1000">
      <TheFooter />
    </LazyComponent>
  </div>
</template>

<script>
import UspBar from '@/components/basic/atoms/UspBar.vue'
import TheHeader from '@/components/basic/organisms/TheHeader.vue'
import TheJsonLd from '@/components/cms/atoms/TheJsonLd.vue'
import TheCmsTemplate from '@/components/cms/templates/TheCmsTemplate.vue'
import {
  containsPrivateInfo,
  NO_INDEX_FOLLOW
} from '@/services/helpers/MetaHelper.js'
import { useCmsPagesStore } from '@/stores/cmsPages'
import { useCustomerStore } from '@/stores/customer'
import { useRecommendationStore } from '@/stores/recommendations'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const AppBanner = defineAsyncComponent(
  () => import('@/components/basic/molecules/AppBanner.vue')
)
const AlertBanner = defineAsyncComponent(
  () => import('@/components/basic/atoms/AlertBanner.vue')
)
const TheFooter = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheFooter.vue')
)

const STATUS = {
  DISABLED: 1,
  ENABLED: 2
}

export default {
  head() {
    return {
      title: this.title,
      meta: [
        {
          name: 'robots',
          content: containsPrivateInfo() ? NO_INDEX_FOLLOW : this.page.robots
        },
        { name: 'description', content: this.page.meta_description }
      ]
    }
  },
  components: {
    TheJsonLd,
    TheCmsTemplate,
    AppBanner,
    AlertBanner,
    TheHeader,
    LazyComponent,
    TheFooter,
    UspBar
  },
  data() {
    return {
      cmsPageId: undefined,
      done: false
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    ...mapState(useCmsPagesStore, ['page']),
    title() {
      return this.page.title ?? this.page.name ?? ''
    },
    showAppBanner() {
      if (this.page.page_id) {
        return this.page.status === STATUS.DISABLED && this.customerIsAdmin
      }
      return false
    },
    showBlankPage() {
      return location.pathname.endsWith('-bp')
    },
    pageType() {
      return this.$route.name === 'home' ? 'page_type:home' : 'page_type:cms'
    }
  },
  methods: {
    ...mapActions(useCustomerStore, ['waitUntilPending']),
    ...mapActions(useCmsPagesStore, ['fetchCmsPage']),
    async fetchPage(pageId) {
      try {
        this.done = false

        this.cmsPageId = pageId
        await this.fetchCmsPage(pageId)

        await this.checkPageStatus()

        this.done = true
      } catch (e) {
        await this.$router.push('/error')
      }
    },
    async checkPageStatus() {
      if (this.page.status === STATUS.ENABLED) return

      await this.waitUntilPending()
      if (this.customerIsAdmin) return

      await this.$router.push('/error')
    }
  },
  beforeRouteEnter({ params: { id } }, _, next) {
    next((vm) => {
      vm.fetchPage(id ?? null)
    })
  },
  beforeRouteUpdate({ params: { id } }, _, next) {
    this.fetchPage(id ?? null)
    next()
  },
  beforeCreate() {
    const recommendations = useRecommendationStore()
    recommendations.fetch()
  }
}
</script>
