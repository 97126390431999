const LOCAL_MAPPING = {
  'vicampo.de': 'de',
  'vicampo.at': 'at',
  'vicampo.ch': 'ch',
  'vicampo.test': 'de',
  'vicampo-at.test': 'at',
  'vicampo-ch.test': 'ch'
}

const devMode = import.meta.env.VITE_APP_DEV_MODE === 'ON'
const API_REGEX = /^(www.|fe-app.)?/

class EnvironmentHelper {
  static detectTopLevelDomain() {
    if (!devMode) {
      return _getTopLevelDomain()
    }
    return LOCAL_MAPPING[_getDevDomain()] || 'de'
  }

  static detectDomain() {
    if (devMode) {
      return import.meta.env.VITE_APP_DOMAIN || location.hostname
    }
    return location.hostname.replace('fe-app.', 'www.')
  }

  static detectApiDomain(domain) {
    return domain.replace(API_REGEX, 'api.')
  }
}

const _getDevDomain = () => location.hostname?.split('.').slice(-2).join('.')
const _getTopLevelDomain = () => {
  const levels = location.hostname.split('.')
  return levels[levels.length - 1]
}

export default EnvironmentHelper
