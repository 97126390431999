<template>
  <var v-if="jsonLd">
    <!--    <script v-html="jsonLd" type="application/ld+json"></script>-->
  </var>
</template>

<script>
import { mapState } from 'pinia'
import { useCmsPagesStore } from '@/stores/cmsPages'

export default {
  computed: {
    ...mapState(useCmsPagesStore, ['page']),
    pageName() {
      return this.page.name?.toLowerCase() || ''
    },
    jsonLd() {
      const { pathname, hostname } = location
      if (pathname !== '/' && this.pageName.indexOf('homepage') === -1)
        return null

      return {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: `https://${hostname}`,
        potentialAction: {
          '@type': 'SearchAction',
          target: `https://${hostname}/search?q={search_term_string}`,
          'query-input': 'required name=search_term_string'
        }
      }
    }
  }
}
</script>
