import { translate } from '@/plugins/i18n.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useHead } from '@unhead/vue'
import { reactive } from 'vue'
import { useRoute } from 'vue-router'
import { NO_INDEX_FOLLOW } from '../services/helpers/MetaHelper.js'

const usePage = () => {
  const customer = useCustomerStore()
  const route = useRoute()
  const meta = reactive({
    title: '',
    description: '',
    robots: NO_INDEX_FOLLOW,
    canonical: '',
    alternate: [],
    translateValues: false
  })
  const unHead = () => {
    const {
      title,
      description,
      canonical,
      alternate,
      robots,
      translateValues
    } = meta

    useHead({
      title: _translateTag(translateValues, title),
      meta: [
        {
          name: 'description',
          content: _translateTag(translateValues, description)
        },
        { name: 'robots', content: _getRobots(route, robots) }
      ],
      link: _getLinks(canonical, alternate)
    })
  }

  return { customer, route, meta, unHead }
}

const _getRobots = (() => {
  const PRIVATE_PARAMS = ['ph', 'email', 'coupon']
  return ({ query }, robots) =>
    PRIVATE_PARAMS.find((p) => Object.hasOwn(query, p)) !== undefined
      ? NO_INDEX_FOLLOW
      : robots
})()

const _getLinks = (canonical, alternate) => {
  const links = canonical ? [] : [{ rel: 'canonical', href: canonical }]

  return [
    ...links,
    ...alternate.map(({ hreflang, href }) => ({
      rel: 'alternate',
      hreflang,
      href
    }))
  ]
}

const _translateTag = (doTranslate, v) => (doTranslate && v ? translate(v) : v)

export { usePage }
