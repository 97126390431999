import lozad from 'lozad'

class LozadHelper {
  initLozad () {
    if (LozadHelper.Singleton) return

    _setupPolyfill()
    LozadHelper.Singleton = this
    LozadHelper.observer = lozad('.lozad', {
      rootMargin: '300px 0px' // syntax similar to that of CSS Margin
    })
    LozadHelper.observer.observe()
  }

  hitLozad () {
    if (!LozadHelper.observer) {
      this.initLozad()
    }
    LozadHelper.observer.observe()
  }

  /**
   * @param {Element} fragment
   */
  async forceLozad (fragment) {
    if (!fragment?.getElementsByClassName) return
    if (!LozadHelper.observer) this.initLozad()

    const { HTML_CLASS: { LOZAD_SELECTOR } } = await import('@/constants/GlobalConstants')
    const lazyLoads = fragment.getElementsByClassName(LOZAD_SELECTOR)

    Array.from(lazyLoads).forEach(elem => {
      LozadHelper.observer.triggerLoad(elem)
    })
  }
}

function _setupPolyfill () {
  // Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
  (function (arr) {
    arr.forEach(function (item) {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('append')) {
        return
      }
      /* istanbul ignore next */
      Object.defineProperty(item, 'append', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function append () {
          var argArr = Array.prototype.slice.call(arguments)
          var docFrag = document.createDocumentFragment()

          argArr.forEach(function (argItem) {
            var isNode = argItem instanceof Node
            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)))
          })

          this.appendChild(docFrag)
        }
      })
    })
  })([Element.prototype, Document.prototype, DocumentFragment.prototype])
}

export default new LozadHelper()
