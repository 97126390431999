<template>
  <div
    id="content"
    data-elbglobals="page_type:search"
    data-elbcontext="shopping:discover"
  >
    <AppBanner />
    <AlertBanner
      v-if="!pageIsVisible"
      :text="translate('alert_message_preview_mode')"
    />
    <UspBar />
    <TheHeader />
    <main id="page-template">
      <TheCategoryTemplate />
    </main>
    <LazyComponent id="footer" :delay="2000">
      <TheFooter />
    </LazyComponent>
  </div>
</template>

<script>
import UspBar from '@/components/basic/atoms/UspBar.vue'
import TheHeader from '@/components/basic/organisms/TheHeader.vue'
import TheCategoryTemplate from '@/components/category/templates/TheCategoryTemplate.vue'
import {
  containsPrivateInfo,
  NO_INDEX_FOLLOW
} from '@/services/helpers/MetaHelper.js'
import { useSearchConfig } from '@/stores/searchConfig'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const AppBanner = defineAsyncComponent(
  () => import('@/components/basic/molecules/AppBanner.vue')
)
const AlertBanner = defineAsyncComponent(
  () => import('@/components/basic/atoms/AlertBanner.vue')
)
const TheFooter = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheFooter.vue')
)

const NAME = 'TheCategoryPage'

export default {
  head() {
    return {
      title: this.title,
      meta: [
        {
          name: 'robots',
          content: containsPrivateInfo()
            ? NO_INDEX_FOLLOW
            : this.category.robots
        },
        { name: 'description', content: this.category.meta_description }
      ]
    }
  },
  name: NAME,
  components: {
    AppBanner,
    AlertBanner,
    TheHeader,
    TheCategoryTemplate,
    LazyComponent,
    TheFooter,
    UspBar
  },
  computed: {
    ...mapState(useSearchConfig, ['category', 'getConfig']),
    footer() {
      return TheFooter
    },
    title() {
      return this.translate('search_page_title', this.searchTerm)
    },
    pageIsVisible() {
      return this.category.is_enabled
    },
    searchTerm() {
      return this.getConfig.query?.original
    },
    q() {
      return this.$route.query.q
    }
  },
  methods: {
    ...mapActions(useSearchConfig, ['fetchCategory'])
  },
  async created() {
    await this.fetchCategory()
  },
  watch: {
    q() {
      this.fetchCategory()
    }
  }
}
</script>
