import { getCurrentInstance } from 'vue'

const useGlobals = () => {
  const { $globals, translate, hitLozad, getVariable, getBlock } = _getGlobals()
  return {
    $globals,
    getVariable,
    getBlock,
    translate,
    hitLozad
  }
}

const _getGlobals = (() => {
  let cache

  return () => {
    if (cache) return cache

    const {
      appContext: {
        config: { globalProperties }
      }
    } = getCurrentInstance()
    cache = globalProperties
    return globalProperties
  }
})()

export { useGlobals }
