import { translate } from '@/plugins/i18n.js'

const PDP_CONFIG = 'robots,redirect_url,canonical_for,alternates'
const PDP_CONFIG_V1 = 'embed=awards,bundle_items,expert_reviews'

const getFirstAvailableStock = (product) =>
  isApiProduct(product)
    ? _getApiStock(product.stocks)
    : _getMetaStock(product.delivery)

const isInStock = (product) => {
  if (isApiProduct(product)) {
    return !!getFirstAvailableStock(product)
  }
  return product.delivery?.is_in_stock
}

const getUnitPrice = async (product) => {
  if (!isApiProduct(product)) return product.price.price_per_unit_formatted

  const { UtilityHelper, filters } = await _getModules()
  const unit = product.reference_unit.toUpperCase()

  return UtilityHelper.formatString(
    '{0} ({1} {2})',
    await getPricePerUnit(
      product.final_price,
      product.reference_unit_contents,
      unit
    ),
    filters.float(product.reference_unit_contents),
    unit
  )
}

const getPricePerUnit = async (price, contents, unit) => {
  const { UtilityHelper, filters } = await _getModules()
  const pricePerUnit = price / contents
  return UtilityHelper.formatString(
    '{0}/{1}',
    filters.currency(pricePerUnit),
    unit
  )
}

const getSavings = async (product) => {
  const { UtilityHelper } = await _getModules()

  if (!isApiProduct(product)) return product.price.savings.percentage

  return product.original_price !== product.final_price
    ? UtilityHelper.formatString(
        '-%s%',
        Math.round(
          (product.original_price - product.final_price) /
            (product.original_price / 100)
        )
      )
    : null
}

const createPriceObject = async (product) => {
  const { TranslationsHelper, SiteConfig } = await _getModules()
  if (!isApiProduct(product)) {
    return {
      finalPrice: product.price.final,
      finalPriceFormatted: product.price.final_formatted,
      originalPrice: product.price.original,
      unitPrice: await getUnitPrice(product),
      savings: await getSavings(product),
      validUntil: product.price.valid_until
        ? new Date(product.price.valid_until).toISOString()
        : null,
      currency: SiteConfig.currencyConfig.currency,
      showUnitPrice: true,
      showCountdown: product.price.show_countdown
    }
  }

  return {
    finalPrice: product.final_price,
    finalPriceFormatted: TranslationsHelper.formatCurrency(product.final_price),
    originalPrice: product.original_price,
    unitPrice: await getUnitPrice(product),
    savings: await getSavings(product),
    validUntil: product.valid_until
      ? new Date(product.valid_until).toISOString()
      : null,
    showUnitPrice: product.unit_type !== 'piece',
    currency: SiteConfig.currencyConfig.currency,
    showCountdown: product.show_countdown
  }
}

const createPriceObjectForUpsell = async (product) => {
  const { SiteConfig, filters } = await _getModules()
  const formatted = translate('fe_app_pdp_final_price_format', 0)

  const unitPrice = (product.price.price_per_unit_formatted ?? '').replace(
    /[^/]+(.+)/,
    `${filters.currency(0)}$1`
  )
  return {
    finalPrice: 0,
    finalPriceFormatted: formatted,
    originalPrice: product.price.final,
    unitPrice: unitPrice,
    savings: '-100%',
    validUntil: new Date(Date.now() + 100000).toISOString(),
    currency: SiteConfig.currencyConfig.currency,
    showUnitPrice: true,
    showCountdown: false
  }
}

const getCartPayload = (product, qty, index, itemListId) => {
  if (isApiProduct(product)) {
    return {
      qty,
      itemListId,
      index,
      productId: +product.product_id,
      campaignId: +product.campaign_id,
      name: product.name,
      price: product.final_price,
      originalPrice: product.original_price,
      variant: product.campaign_name
    }
  }

  return {
    qty,
    itemListId,
    index,
    productId: +product.product_id,
    campaignId: +product.campaign_id,
    name: product.name,
    price: product.final_price,
    originalPrice: product.original_price,
    variant: product.ga_data.variant
  }
}

const isApiProduct = (product) =>
  Object.hasOwn(product, 'campaign_id') && Object.hasOwn(product, 'is_opc')
const isMetaProduct = (product) => Object.hasOwn(product, 'recommendation')

export {
  createPriceObject,
  createPriceObjectForUpsell,
  getCartPayload,
  getFirstAvailableStock,
  getPricePerUnit,
  getSavings,
  getUnitPrice,
  isApiProduct,
  isInStock,
  isMetaProduct,
  PDP_CONFIG,
  PDP_CONFIG_V1
}

const _getApiStock = (stocks) => stocks.find((stock) => stock.is_in_stock)

const _getMetaStock = (delivery) =>
  delivery?.is_in_stock ? { is_in_stock: true, is_enabled: true } : null

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: SiteConfig },
      { default: UtilityHelper },
      { default: TranslationsHelper },
      { default: filters }
    ] = await Promise.all([
      await import('@/services/SiteConfig'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/services/helpers/TranslationsHelper'),
      await import('@/services/Filters')
    ])
    cache = {
      SiteConfig,
      UtilityHelper,
      TranslationsHelper,
      filters
    }
    return cache
  }
})()
