<template>
  <div
    id="category-wrapper"
    class="position-relative legacy-inputs"
    :data-elbcontext="elbContext"
  >
    <div>
      <TheSearchHeader v-if="isSearch" />
      <CategorySmartBlock
        v-else-if="category.code"
        :block="blocks.before"
        :enable-cms="enableCms"
      />
    </div>
    <TheFilter v-if="category.code" />
    <LazyComponent v-if="customerSoftLoggedIn" id="after-html" :delay="1500">
      <CategorySmartBlock
        v-if="category.code"
        :block="blocks.after"
        :enable-cms="enableCms"
      />
    </LazyComponent>
    <LazyComponent v-if="customerSoftLoggedIn" id="recent-items" :delay="1500">
      <TheRecentlyViewedItems class="border-winered pt-5" />
    </LazyComponent>
    <MultiTools id="fixed-tools" v-if="customerIsAdmin && adminTool">
      <Tool :type="adminTool.TRANSLATE_MODE" />
      <Tool :type="adminTool.HIGHLIGHT_CMS_ITEMS" />
      <Tool :type="adminTool.TOGGLE_DATALAYER_LOGGING" />
      <Tool :type="adminTool.TOGGLE_ELBWALKER_LOGGING" />
      <Tool :type="adminTool.EDIT_CATEGORY" :id="category.code" />
      <Tool v-if="isSearch" :type="adminTool.TOGGLE_SOLR_DATA" />
      <Tool :type="adminTool.TOGGLE_HIGH_CONTRAST" />
      <Tool :type="adminTool.TEXT_INCREASE" />
      <Tool :type="adminTool.TEXT_PERCENTAGE" />
      <Tool :type="adminTool.TEXT_DECREASE" />
    </MultiTools>
  </div>
</template>

<script>
import TheFilter from '@/components/category/organisms/TheFilter.vue'
import { useCustomerStore } from '@/stores/customer'
import { useSearchConfig } from '@/stores/searchConfig'
import { mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const MultiTools = defineAsyncComponent(
  () => import('@/components/basic/molecules/MultiTools.vue')
)
const Tool = defineAsyncComponent(
  () => import('@/components/basic/atoms/Tool.vue')
)
const TheRecentlyViewedItems = defineAsyncComponent(
  () => import('@/components/basic/molecules/TheRecentlyViewedItems.vue')
)
const CategorySmartBlock = defineAsyncComponent(
  () => import('@/components/category/molecules/CategorySmartBlock.vue')
)
const TheSearchHeader = defineAsyncComponent(
  () => import('@/components/category/atoms/TheSearchHeader.vue')
)

export default {
  name: 'TheCategoryTemplate',
  components: {
    LazyComponent,
    CategorySmartBlock,
    TheSearchHeader,
    TheFilter,
    Tool,
    MultiTools,
    TheRecentlyViewedItems
  },
  data() {
    return {
      contentReady: false,
      adminTool: null
    }
  },
  computed: {
    ...mapState(useSearchConfig, [
      'category',
      'blocks',
      'getConfig',
      'elbwalkerContext',
      'enableCms'
    ]),
    ...mapState(useCustomerStore, ['customerIsAdmin', 'customerSoftLoggedIn']),
    isSearch() {
      return !!this.getConfig.query?.original
    },
    elbContext() {
      return this.elbwalkerContext.join(';')
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.contentReady = true
    })
  },
  async created() {
    const { TYPES } = await import('@/constants/AdminTool')
    this.adminTool = TYPES

    if (this.getConfig && this.blocks?.length) {
      this.contentReady = true
    }
  }
}
</script>
<style scoped>
#category-wrapper {
  min-height: 100vh;
}

.fade-enter-active {
  transition: all var(--transition-duration-xxxxl)
    var(--transition-timing-cb-vicampo);
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}
</style>
