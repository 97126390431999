<template>
  <header
    id="header-wrapper"
    class="mb-3"
    :class="{
      'md-and-up position-sticky top-0 z-10': $vuetify.display.mdAndUp
    }"
  >
    <CmsBlock identifier="fe_app_maintenance" disable-animation hide-on-error />
    <CmsBlock identifier="before_header_v2" disable-animation hide-on-error />

    <div
      :style="{
        background: `rgb(var(--v-theme-white))`
      }"
      class="content d-flex flex-column"
    >
      <VContainer class="c-gray f-gray py-0 py-md-3 px-3 flex-grow-1">
        <div id="main-header" class="v-row justify-space-between align-center">
          <div
            v-if="this.$vuetify.display.mdAndUp"
            class="v-col-5 v-col-md-3"
            itemscope
            itemtype="https://schema.org/Brand"
          >
            <a href="/" class="d-flex w-100" @click.prevent="goToHome">
              <VImg :src="logoSrc" max-height="75" alt="vicampo-logo" />
            </a>
          </div>

          <SearchForm
            v-if="this.$vuetify.display.mdAndUp"
            class="'v-col-7 v-col-md-5 v-col-lg-6'"
          />
          <CustomerNavigation class="v-col-12 v-col-md-4 v-col-lg-3" />
          <SearchForm v-if="!this.$vuetify.display.mdAndUp" class="px-5" />
        </div>
      </VContainer>

      <HeaderNavigation v-if="$vuetify.display.mdAndUp" />
    </div>
  </header>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import logoMain from '@/assets/images/logo-main.svg'
import { VImg } from 'vuetify/components'

const CustomerNavigation = defineAsyncComponent(
  () => import('@/components/basic/molecules/CustomerNavigation.vue')
)
const CmsBlock = defineAsyncComponent(
  () => import('@/components/basic/atoms/CmsBlock.vue')
)
const SearchForm = defineAsyncComponent(
  () => import('@/components/basic/atoms/TheSearchForm.vue')
)
const HeaderNavigation = defineAsyncComponent(
  () => import('@/components/basic/molecules/HeaderNavigation.vue')
)

export default {
  components: {
    CustomerNavigation,
    CmsBlock,
    SearchForm,
    HeaderNavigation,
    VImg
  },
  computed: {
    logoSrc() {
      return logoMain
    }
  },
  methods: {
    goToHome() {
      this.$route.path !== '/' ? this.$router.push('/') : this.$router.go('/')
    }
  }
}
</script>

<style scoped>
.content {
  min-height: 150px;
}

.md-and-up .content {
  min-height: 150px;
}

#main-header > div,
#main-header > form {
  display: flex;
  align-items: center;
  height: 75px;
}
</style>
