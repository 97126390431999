const CustomerMetaSchema = {
  name: 'CustomerMetaSchema',
  fields: {
    customer_id: null,
    full_name: null,
    first_name: null,
    last_name: null,
    email: null,
    salutation: null,
    is_admin: null,
    meta_navigation: null,
    credits_formatted: null,
    credits_expire_days_left: null,
    flash_messages: [],
    admin_data: {},
    admin_url_backend: {},
    state: {},
    completed_orders_count: {},
    days_since_last_order: {},
    is_newsletter_subscribed: null,
    dob: null,
    ip_address: null
  }
}

export default CustomerMetaSchema
