import { translate } from '@/plugins/i18n.js'
import filters from '@/services/Filters'
import UtilityHelper from '@/services/helpers/UtilityHelper'

let enabled
export const TRANSLATIONS_TOGGLE = 'disable_translations'

class TranslationsHelper {
  constructor() {
    if (TranslationsHelper.Singleton) return
    TranslationsHelper.Singleton = this

    enabled = !localStorage.getItem(TRANSLATIONS_TOGGLE)
  }

  translate(key, ...data) {
    return enabled ? UtilityHelper.formatString(translate(key), ...data) : key
  }

  formatCurrency(number, fraction = 2) {
    if (
      isNaN(number) ||
      typeof number === 'boolean' ||
      typeof number === 'object'
    ) {
      console.error('Invalid number was passed to formatCurrency')
      return ''
    }
    return translate(
      'fe_app_pdp_final_price_format',
      filters.float(number, fraction, false, true)
    )
  }

  async toggleTranslations() {
    enabled = !enabled
  }
}

export default new TranslationsHelper()
