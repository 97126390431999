const PRIVATE_PARAMS = ['ph', 'email', 'coupon']
const LINK_TAGS = ['canonical', 'alternate']

export const NO_INDEX_FOLLOW = 'noindex, follow'
export const INDEX_FOLLOW = 'index, follow'

export const createMetaObject = async ({ title, ...rest }) => {
  const { SiteConfig } = await _getModules()

  const prefix = SiteConfig.devMode ? '[ 👾] ' : ''
  const titleTemplate = `${prefix}%s | ${SiteConfig.name}`
  const pagePrivate = containsPrivateInfo()

  const { meta, link } = mapMetaData(rest, pagePrivate)

  return { titleTemplate, title, meta, link }
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ default: SiteConfig }, { default: router }] = await Promise.all([
      await import('@/services/SiteConfig'),
      await import('@/router')
    ])
    cache = {
      SiteConfig,
      router
    }
    return cache
  }
})()

export const containsPrivateInfo = () => {
  const query = new URLSearchParams(location.search)
  return PRIVATE_PARAMS.some((p) => query.has(p))
}

const mapMetaData = (data, noIndex) => {
  // [ { rel: 'something', random_prop: '123' } ]
  const link = Object.keys(data)
    .filter((key) => LINK_TAGS.includes(key))
    .reduce(
      (tags, key) => [
        ...tags,
        ...data[key].map((content) => ({ rel: key, ...content }))
      ],
      []
    )

  // [ { vmid: '', name: '', content: '' } ]
  const meta = Object.keys(data)
    .filter((key) => !LINK_TAGS.includes(key))
    .reduce((tags, key) => {
      if (!data[key]) {
        return tags
      }
      if (key === 'robots' && noIndex) {
        return [...tags, { vmid: key, name: key, content: NO_INDEX_FOLLOW }]
      }
      return [...tags, { vmid: key, name: key, content: data[key] }]
    }, [])

  return { meta, link }
}
