import filters from '@/services/Filters.js'
import { TRANSLATIONS_TOGGLE } from '@/services/helpers/TranslationsHelper.js'
import UtilityHelper from '@/services/helpers/UtilityHelper.js'
import SiteConfig from '@/services/SiteConfig.js'
import { createI18n } from 'vue-i18n'

let service
let enabled = !localStorage.getItem(TRANSLATIONS_TOGGLE)

const setupTranslations = async () => {
  if (service) return service

  const locale = SiteConfig.lang
  const fallbackLocale = 'de-DE'
  const [{ default: translations }, { default: fallback }] = await Promise.all([
    await import(`@/assets/translations/${locale}.json`),
    await import(`@/assets/translations/${fallbackLocale}.json`)
  ])
  const messages = {
    [locale]: translations,
    [fallbackLocale]: fallback
  }

  service = createI18n({
    legacy: false,
    warnHtmlMessage: false,
    locale,
    fallbackLocale,
    messages,
    missingWarn: false
  })

  return service
}

const translate = (key, ...data) =>
  enabled
    ? UtilityHelper.formatString(service.global.t(key, data), ...data)
    : key

const formatCurrency = (number, fraction = 2) => {
  if (
    isNaN(number) ||
    typeof number === 'boolean' ||
    typeof number === 'object'
  ) {
    console.error('Invalid number was passed to formatCurrency')
    return ''
  }
  return translate(
    'fe_app_pdp_final_price_format',
    filters.float(number, fraction, false)
  )
}

// init and install have to be done separately because it cannot work with async
const install = (app, i18n) => {
  app.use(i18n)

  app.config.globalProperties.translate = (key, ...params) =>
    translate(key, ...params)
  app.config.globalProperties.formatCurrency = (number, fraction) =>
    formatCurrency(number, fraction)
}

export { install, setupTranslations, translate, formatCurrency }
