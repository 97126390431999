<template>
  <VContainer
    id="mainfacts"
    class="mt-2 d-flex pa-0 font-size-m cursor-default justify-center"
  >
    <VSheet class="w-100">
      <VRow class="justify-center">
        <div
          v-if="product.details.wine_type"
          class="v-col-4 v-col-md-2 py-0 d-flex justify-center"
        >
          <MainFact
            :img="'bottle.svg'"
            img-alt="Type"
            :text="product.details.wine_type"
            :tooltip="translate('pdp_attribute_wine_type')"
          />
        </div>
        <div
          v-if="product.details.wine_grape_variety"
          class="v-col-4 v-col-md-2 py-0 d-flex justify-center"
        >
          <MainFact
            :img="'grapes.svg'"
            img-alt="Grape Variety"
            :text="product.details.wine_grape_variety"
            :tooltip="translate('pdp_attribute_grape_variety')"
          />
        </div>
        <div
          v-if="product.details.wine_expansion"
          class="v-col-4 v-col-md-2 py-0 d-flex justify-center"
        >
          <MainFact
            :img="'glass.svg'"
            img-alt="Taste"
            :text="product.details.wine_expansion"
            :tooltip="translate('pdp_attribute_vinification')"
          />
        </div>
        <div
          v-if="product.details.country"
          class="v-col-4 v-col-md-2 py-0 d-flex justify-center"
        >
          <MainFact
            :img="'earth.svg'"
            img-alt="Country"
            :text="product.details.country"
            :tooltip="translate('pdp_attribute_country')"
          />
        </div>
        <div
          v-if="product.details.region"
          class="v-col-4 v-col-md-2 py-0 d-flex justify-center"
        >
          <MainFact
            :img="'fields.svg'"
            img-alt="Region"
            :text="product.details.region"
            :tooltip="translate('pdp_attribute_region')"
          />
        </div>
        <div
          v-if="product.details.wine_year"
          class="v-col-4 v-col-md-2 py-0 d-flex justify-center"
        >
          <MainFact
            :img="'calender.svg'"
            img-alt="Year"
            :text="product.details.wine_year"
            :tooltip="translate('pdp_attribute_vintage')"
          />
        </div>
      </VRow>
    </VSheet>
  </VContainer>
</template>

<script>
import { usePdpStore } from '@/stores/pdp'
import { mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

const MainFact = defineAsyncComponent(
  () => import('@/components/pdp/atoms/MainFact.vue')
)

export default {
  components: { MainFact },
  computed: {
    ...mapState(usePdpStore, { product: 'getApiProduct' })
  }
}
</script>

<style scoped>
#mainfacts {
  min-height: 92px;
}
</style>
