import { h } from 'vue'
import IconCheckboxOff from '../../components/basic/atoms/IconCheckboxOff.vue'
import IconCheckboxOn from '../../components/basic/atoms/IconCheckboxOn.vue'
import IconClose from '../../components/basic/atoms/IconClose.vue'
import IconCollapse from '../../components/basic/atoms/IconCollapse.vue'
import IconDown from '../../components/basic/atoms/IconDown.vue'
import IconExpand from '../../components/basic/atoms/IconExpand.vue'
import IconEyeOff from '../../components/basic/atoms/IconEyeOff.vue'
import IconEyeOn from '../../components/basic/atoms/IconEyeOn.vue'
import IconInfoFilled from '../../components/basic/atoms/IconInfoFilled.vue'
import IconLeft from '../../components/basic/atoms/IconLeft.vue'
import IconRadioButtonOff from '../../components/basic/atoms/IconRadioButtonOff.vue'
import IconRadioButtonOn from '../../components/basic/atoms/IconRadioButtonOn.vue'
import IconRight from '../../components/basic/atoms/IconRight.vue'
import IconSearch from '../../components/basic/atoms/IconSearch.vue'
import IconUp from '../../components/basic/atoms/IconUp.vue'

const aliases = {
  collapse: IconCollapse,
  expand: IconExpand,
  infoFilled: IconInfoFilled,
  eyeOn: IconEyeOn,
  eyeOff: IconEyeOff,
  checkboxOn: IconCheckboxOn,
  checkboxOff: IconCheckboxOff,
  radioButtonOn: IconRadioButtonOn,
  radioButtonOff: IconRadioButtonOff,
  search: IconSearch,
  close: IconClose,
  down: IconDown,
  up: IconUp,
  left: IconLeft,
  right: IconRight
}

const customIcons = {
  component: (props) => h(aliases[props.icon])
}

export { customIcons }
