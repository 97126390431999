import '@/assets/styles/fonts.css'
import '@/assets/styles/global.scss'
import '@/external_scripts'
import '@/plugins/elbwalker'
import '@/services/SiteConfig.js'

import cms from '@/plugins/cms.js'
import filters from '@/plugins/filters.js'
import {
  formatCurrency,
  setupTranslations,
  translate,
  install as translationPlugin
} from '@/plugins/i18n.js'
import lozad from '@/plugins/lozad.js'
import router from '@/router/index.js'
import { createPinia } from 'pinia'
import { createApp, markRaw, reactive, watch } from 'vue'

import App from '@/App.vue'
import vuetify from '@/plugins/vuetify.js'
import { createHead, VueHeadMixin } from '@unhead/vue'
import { getComponentDefaults } from './plugins/vuetify'
import { HIGH_CONTRAST_TOGGLE } from './stores/accessibility'

const MOUNT_POINT = '#app'

;(async () => {
  const app = createApp(App)
  const pinia = createPinia()
  const i18n = await setupTranslations()
  const head = createHead()

  app.config.globalProperties.$globals = reactive({})

  pinia.use(({ store }) => {
    store.router = markRaw(router)
    store.translate = markRaw(translate)
    store.formatCurrency = markRaw(formatCurrency)
  })

  app.use(pinia)
  app.use(router)
  app.use(vuetify)
  app.use(translationPlugin, i18n)
  app.use(lozad)
  app.use(filters)
  app.use(cms)
  app.use(head)
  app.mixin(VueHeadMixin)
  app.mount(MOUNT_POINT)

  if (localStorage.getItem(HIGH_CONTRAST_TOGGLE) === 'true') {
    vuetify.defaults.value = getComponentDefaults('vicampoThemeHighContrast')
  }

  watch(
    () => vuetify.theme.global.name.value,
    (newTheme) => {
      vuetify.defaults.value = getComponentDefaults(newTheme)
    }
  )
})()
