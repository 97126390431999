<template>
  <div class="position-relative">
    <ProductStage data-elblink="product:parent" />
    <RateProduct
      class="mt-2"
      v-if="hasBeenBought && productLoaded"
      :product-id="product.product_id"
      :is-simple="productIsSimple"
      :date="lastPurchaseDate"
      :rating="rating"
    />
    <MainFacts v-if="!unitTypePiece" />
    <TheCrossSells
      v-if="productIsSimple"
      :key="'cross-sells-' + product.product_id"
    />

    <Details data-elblink="product:child" />

    <TheRecentlyViewedItems
      v-if="customerSoftLoggedIn"
      :key="'recent-items-' + product.product_id"
      class="border-winered pt-5"
    />

    <MultiTools id="fixed-tools" v-if="customerIsAdmin && productLoaded">
      <Tool :type="adminTool.TRANSLATE_MODE" />
      <Tool :type="adminTool.HIGHLIGHT_CMS_ITEMS" />
      <Tool :type="adminTool.TOGGLE_DATALAYER_LOGGING" />
      <Tool :type="adminTool.TOGGLE_ELBWALKER_LOGGING" />
      <Tool
        :type="adminTool.EDIT_PRODUCT"
        :id="product.product_id"
        :campaign-id="product.campaign_id"
      />
      <Tool :type="adminTool.RELATED_PRODUCTS" :id="product.product_id" />
      <Tool :type="adminTool.TOGGLE_HIGH_CONTRAST" />
      <Tool :type="adminTool.TEXT_INCREASE" />
      <Tool :type="adminTool.TEXT_PERCENTAGE" />
      <Tool :type="adminTool.TEXT_DECREASE" />
    </MultiTools>
    <ThePdpAddToCartMobile
      v-if="$vuetify.display.mdAndDown && product.product_id"
      data-elblink="product:child"
    />
  </div>
</template>

<script>
import { TYPES } from '@/constants/AdminTool'
import { PRODUCT_TYPES } from '@/constants/GlobalConstants'
import ElbwalkerHelper from '@/services/helpers/ElbwalkerHelper'
import { useCustomerStore } from '@/stores/customer'
import { usePdpStore } from '@/stores/pdp'
import { mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'
import MainFacts from '../molecules/MainFacts.vue'
import ProductStage from '../organisms/ProductStage.vue'

const ThePdpAddToCartMobile = defineAsyncComponent(
  () => import('@/components/pdp/atoms/ThePdpAddToCartMobile.vue')
)
const RateProduct = defineAsyncComponent(
  () => import('@/components/pdp/molecules/RateProduct.vue')
)
const MultiTools = defineAsyncComponent(
  () => import('@/components/basic/molecules/MultiTools.vue')
)
const Tool = defineAsyncComponent(
  () => import('@/components/basic/atoms/Tool.vue')
)
const Details = defineAsyncComponent(
  () => import('@/components/pdp/organisms/Details.vue')
)
const TheRecentlyViewedItems = defineAsyncComponent(
  () => import('@/components/basic/molecules/TheRecentlyViewedItems.vue')
)
const TheCrossSells = defineAsyncComponent(
  () => import('@/components/pdp/molecules/TheCrossSells.vue')
)

export default {
  components: {
    RateProduct,
    Tool,
    MultiTools,
    MainFacts,
    ProductStage,
    TheCrossSells,
    ThePdpAddToCartMobile,
    TheRecentlyViewedItems,
    Details
  },
  data() {
    return {
      adminTool: TYPES
    }
  },
  computed: {
    ...mapState(useCustomerStore, [
      'customerProfile',
      'customerIsAdmin',
      'customerSoftLoggedIn'
    ]),
    ...mapState(usePdpStore, {
      product: 'getApiProduct',
      metaProduct: 'getMetaProduct'
    }),
    productIsSimple() {
      return this.product.type === PRODUCT_TYPES.SIMPLE
    },
    unitTypePiece() {
      return this.product.unit_type === 'piece'
    },
    singleBundle() {
      return this.product.bundle_items.length === 1
    },
    hasBeenBought() {
      return this.metaProduct.has_been_bought
    },
    lastPurchaseDate() {
      return this.metaProduct.last_purchase_date
    },
    rating() {
      return this.metaProduct.rating
    },
    productLoaded() {
      return this.product.product_id && this.product.campaign_id
    },
    elbContext() {
      return ElbwalkerHelper.getProductData(this.product)
    }
  }
}
</script>
