<template>
  <div
    class="my-4 v-container font-size-regular"
    itemprop="contactPoint"
    itemtype="https://schema.org/ContactPoint"
    itemscope
  >
    <VSheet
      class="d-flex flex-column-reverse flex-md-row bg-white py-4 align-center"
    >
      <div
        class="v-col-12 v-col-sm-10 v-col-md-5 pt-5 text-center text-md-left"
      >
        <img class="w-100" :alt="404" src="@/assets/images/404.jpg" />
      </div>
      <div
        class="v-col-12 v-col-md-7 c-gray d-flex flex-column align-center d-md-block text-center text-md-left"
      >
        <h1 class="font-size-xxxl bold c-graphit">
          {{ translate('error_page_sorry') }}
        </h1>
        {{ translate('error_page_not_found') }}
        <div
          class="d-flex flex-column flex-md-row align-center justify-space-between justify-md-start py-4"
        >
          <CmsBlock identifier="error_page_link_1" />
          <span class="d-flex align-center justify-space-around py-3 px-3">
            {{ translate('fe_app_pdp_or') }}
          </span>
          <CmsBlock identifier="error_page_link_2" />
        </div>
        <div class="pb-2">{{ translate('error_page_contact_team') }}</div>
        <div class="pt-1">
          {{ translate('fe_app_pdp_phone') }}
          <a class="link-winered" :href="`tel:${servicePhone.link}`">
            {{ servicePhone.text }}
          </a>
        </div>
        <div class="pt-1">
          {{ translate('fe_app_pdp_email') }}
          <a class="link-winered" :href="`mailto:${serviceMail}`">{{
            serviceMail
          }}</a>
        </div>
      </div>
    </VSheet>
    <MultiTools id="fixed-tools" v-if="adminTool && customerIsAdmin" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useCustomerStore } from '@/stores/customer'
import { mapState } from 'pinia'

const CmsBlock = defineAsyncComponent(
  () => import('@/components/basic/atoms/CmsBlock.vue')
)
const MultiTools = defineAsyncComponent(
  () => import('@/components/basic/molecules/MultiTools.vue')
)

export default {
  components: { CmsBlock, MultiTools },
  data() {
    return {
      adminTool: null,
      serviceMail: '',
      servicePhone: {}
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerIsAdmin'])
  },
  async created() {
    const [
      { TYPES },
      {
        default: { phone, email }
      }
    ] = await Promise.all([
      await import('@/constants/AdminTool'),
      await import('@/services/SiteConfig')
    ])
    this.adminTool = TYPES
    this.servicePhone = phone
    this.serviceMail = email
  }
}
</script>
