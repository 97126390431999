<template>
  <SvgWrapper class="f-clay" use="icon_svg_close" dimensions="18" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const SvgWrapper = defineAsyncComponent(
  () => import('@/components/basic/atoms/SvgWrapper.vue')
)

export default {
  components: { SvgWrapper }
}
</script>
