/* istanbul ignore file */
/* eslint-disable */

let loaded = false

export const loadCookiefirst = async () => {
  if (loaded || import.meta.env.VITE_APP_TRACKING_ENABLED === 'OFF') return

  const domain = location.hostname.split('.').reverse()[0]
  const envVariable = `VITE_APP_COOKIEFIRST_KEY_${domain.toUpperCase()}`
  const apiKey = import.meta.env[envVariable]
  const source = `https://consent.cookiefirst.com/sites/vicampo.${domain}-${apiKey}/consent.js`

  try {
    const response = await fetch(source)
    const script = await response.text()
    // eslint-disable-next-line no-new-func,no-eval
    Function(script)()
  } catch (e) {
    import.meta.env.VITE_APP_DEV_MODE === 'OFF' &&
      console.error('Failed to load cookiefirst:', e)
  }
  loaded = true
}
