<template>
  <VContainer class="pt-2" data-elbcontext="shopping:cart">
    <LoadingOverlay v-if="threeDSecureLoading" />
    <TheOpcErrorMessage />
    <div class="v-row">
      <h3
        id="items"
        class="v-col-6 v-col-lg-8 font-size-xl c-gray my-3 l-spacing-1 font-weight-bold"
      >
        {{ translate('new_cart_page_items_section_title') }}
      </h3>
    </div>
    <div class="v-row flex-row flex-md-row-reverse">
      <div class="sidebar v-col-12 v-col-lg-4">
        <div class="v-row position-sticky">
          <div class="v-col-12 v-col-md-8 v-col-lg-12 px-0 px-md-3">
            <TheCartSummary class="mr-md-3 mr-lg-0 h-100" />
          </div>
          <div v-if="showCartAwards" class="v-col-4 v-col-lg-12 mt-lg-2">
            <TheCartAwards />
          </div>
        </div>
      </div>
      <div class="v-col-12 v-col-lg-8">
        <div v-if="upsellConfig && !selectedUpsell" class="v-row">
          <div class="v-col-12 px-0 px-md-3 mt-3 mt-lg-0 mb-lg-3">
            <TheCartUpsell />
          </div>
        </div>
        <div class="v-row">
          <div
            v-if="showUpsell"
            class="v-col-12 mb-3 mt-3 mt-md-0 px-0 px-lg-3"
          >
            <TheCartUpsellItemsSection
              v-if="selectedUpsell"
              :product-data="selectedUpsell"
              :key="selectedUpsell.product_id"
            />
          </div>
          <div v-if="itemCount < 1 && isUpdating" class="v-col-12 px-0 px-lg-3">
            <div class="bg-white border-b mt-2 mt-lg-0 pa-3">
              <LoadingOverlay
                width="40"
                height="40"
                class="position-relative"
              />
            </div>
          </div>
          <TheCartItemsSection
            v-else
            @sectionUpdated="setupCheckout"
            @sectionRendered="triggerLoad"
            class="v-col-12 px-0 px-md-3 mt-3 mt-lg-0"
          />
          <TheWishlistSection />
          <LazyComponent
            id="cart-teaser"
            :delay="1000"
            class="cart-teaser v-col-12"
          >
            <CmsBlock
              identifier="cart_teaser_v2"
              disable-animation
              hide-on-error
            />
          </LazyComponent>
          <LazyComponent
            v-if="soldOutProducts"
            id="sold-out-section"
            :delay="1000"
            class="v-col-12 px-0 px-md-3"
          >
            <TheCartSoldOutItemsSection />
          </LazyComponent>
        </div>
      </div>
    </div>
  </VContainer>
</template>
<script>
import { useCartStore } from '@/stores/cart'
import { useCheckoutStore } from '@/stores/checkout'
import { useFeaturesStore } from '@/stores/features'
import { useSoldOutProducts } from '@/stores/soldOutProduct'
import { useUpsellStore } from '@/stores/upsell'
import { useWishlistStore } from '@/stores/wishlist.js'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const TheWishlistSection = defineAsyncComponent(
  () => import('@/components/cart/organisms/TheWishlistSection.vue')
)
const CmsBlock = defineAsyncComponent(
  () => import('@/components/basic/atoms/CmsBlock.vue')
)
const LoadingOverlay = defineAsyncComponent(
  () => import('@/components/basic/atoms/LoadingOverlay.vue')
)
const TheCartItemsSection = defineAsyncComponent(
  () => import('@/components/cart/organisms/TheCartItemsSection.vue')
)
const TheCartSoldOutItemsSection = defineAsyncComponent(
  () => import('@/components/cart/organisms/TheCartSoldOutItemsSection.vue')
)
const TheCartSummary = defineAsyncComponent(
  () => import('@/components/cart/organisms/TheCartSummary.vue')
)
const TheCartAwards = defineAsyncComponent(
  () => import('@/components/cart/organisms/TheCartAwards.vue')
)
const TheCartUpsell = defineAsyncComponent(
  () => import('@/components/cart/organisms/TheCartUpsell.vue')
)
const TheCartUpsellItemsSection = defineAsyncComponent(
  () => import('@/components/cart/organisms/TheCartUpsellItemsSection.vue')
)
const TheOpcErrorMessage = defineAsyncComponent(
  () => import('@/components/opc/atoms/TheOpcErrorMessage.vue')
)

export default {
  name: 'TheCartTemplate',
  components: {
    LoadingOverlay,
    TheCartItemsSection,
    TheCartSummary,
    TheCartAwards,
    TheCartUpsell,
    TheCartUpsellItemsSection,
    LazyComponent,
    TheWishlistSection,
    TheCartSoldOutItemsSection,
    CmsBlock,
    TheOpcErrorMessage
  },
  data() {
    return {
      canCheckout: false
    }
  },
  computed: {
    ...mapState(useUpsellStore, {
      upsellConfig: 'getConfig',
      selectedUpsell: 'selectedUpsell'
    }),
    ...mapState(useSoldOutProducts, { soldOutProducts: 'totalCount' }),
    ...mapState(useWishlistStore, { savedProducts: 'totalCount' }),
    ...mapState(useCartStore, ['itemCount', 'isUpdating']),
    ...mapState(useFeaturesStore, { featureEnabled: 'isFeatureEnabled' }),
    ...mapState(useCheckoutStore, ['threeDSecureLoading']),
    showCartAwards() {
      return (
        this.$vuetify.display.lgAndUp ||
        (this.$vuetify.display.mdAndUp && this.canCheckout)
      )
    },
    showUpsell() {
      return this.featureEnabled('cart_upsell') && this.upsellConfig?.is_active
    }
  },
  methods: {
    ...mapActions(useUpsellStore, ['refreshUpsell', 'removeUpsell']),
    ...mapActions(useSoldOutProducts, ['refreshSoldOutProducts']),
    triggerLoad() {
      this.refreshSoldOutProducts()
    },
    setupCheckout(canCheckout) {
      this.canCheckout = canCheckout
      this.$emit('setupCheckout', canCheckout)

      this.refreshUpsell()
    }
  },
  watch: {
    async itemCount() {
      await this.refreshUpsell()
      if (this.upsellConfig?.condition_fulfilled || !this.selectedUpsell) return

      const { cart_item_id: cartItemId } = this.selectedUpsell
      await this.removeUpsell({ cartItemId })
    }
  }
}
</script>
<style scoped>
.sidebar > div {
  top: 0;
}

:deep(.cart-teaser > div) {
  margin-top: 1rem;
}
</style>
