<template>
  <div id="content" itemscope itemtype="https://schema.org/manufacturer">
    <AppBanner />
    <AlertBanner
      v-if="!visible"
      :text="translate('alert_message_preview_mode')"
    />
    <UspBar />
    <TheHeader />
    <transition name="fade">
      <main
        id="page-template"
        class="position-relative"
        data-elbglobals="page_type:manufacturer"
        :data-elbcontext="elbContext"
      >
        <img
          class="bg-image d-none"
          :class="{ 'd-md-block': calculated.bgImage }"
          :src="calculated.bgImage"
          alt="manufacturer background"
          :style="bgHeight"
        />
        <TheIntroSection
          class="v-container px-0 pt-2"
          ref="intro"
          @loaded="adjustBg"
        />
        <div :loading="loading" :class="{ 'v-container': loading }">
          <div
            id="manufacturer-products"
            class="headline-container text-center py-2"
          >
            <h2
              v-html="translate('products_by_manufaturer', manufacturer.name)"
            />
          </div>
          <LazyComponent id="manufacturer-filter" :delay="1000">
            <TheFilter v-if="getProducts.length" class="px-md-0" />
            <TheNoProducts v-else />
          </LazyComponent>
          <div v-if="hasMoreInformation">
            <div class="v-container px-0 pt-2">
              <div
                id="manufacturer-description-section"
                class="w-100 headline-container py-5 text-center"
              >
                <h2 v-html="translate('manufacturer_more_about_winery')" />
              </div>
              <TheDescriptionSection />
            </div>
          </div>
        </div>
        <MultiTools id="fixed-tools" v-if="customerIsAdmin && adminTool">
          <Tool :type="adminTool.EDIT_CATEGORY" :id="category.code" />
          <Tool :type="adminTool.EDIT_MANUFACTURER" :id="$route.params.id" />
          <Tool :type="adminTool.TOGGLE_HIGH_CONTRAST" />
          <Tool :type="adminTool.TEXT_INCREASE" />
          <Tool :type="adminTool.TEXT_PERCENTAGE" />
          <Tool :type="adminTool.TEXT_DECREASE" />
        </MultiTools>
      </main>
    </transition>
    <LazyComponent id="footer" :delay="1500">
      <TheFooter />
    </LazyComponent>
  </div>
</template>

<script>
import UspBar from '@/components/basic/atoms/UspBar.vue'
import {
  containsPrivateInfo,
  NO_INDEX_FOLLOW
} from '@/services/helpers/MetaHelper.js'
import { useCustomerStore } from '@/stores/customer'
import { useManufacturerStore } from '@/stores/manufacturer'
import { useSearchConfig } from '@/stores/searchConfig'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'
import { INDEX_FOLLOW } from '../services/helpers/MetaHelper.js'

const TheHeader = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheHeader.vue')
)
const TheIntroSection = defineAsyncComponent(
  () => import('@/components/manufacturer/molecules/TheIntroSection.vue')
)
const TheNoProducts = defineAsyncComponent(
  () => import('@/components/manufacturer/atoms/TheNoProducts.vue')
)
const LazyComponent = defineAsyncComponent(
  () => import('@/components/basic/atoms/LazyComponent.vue')
)
const AppBanner = defineAsyncComponent(
  () => import('@/components/basic/molecules/AppBanner.vue')
)
const AlertBanner = defineAsyncComponent(
  () => import('@/components/basic/atoms/AlertBanner.vue')
)
const TheDescriptionSection = defineAsyncComponent(
  () => import('@/components/manufacturer/molecules/TheDescriptionSection.vue')
)
const TheFilter = defineAsyncComponent(
  () => import('@/components/category/organisms/TheFilter.vue')
)
const MultiTools = defineAsyncComponent(
  () => import('@/components/basic/molecules/MultiTools.vue')
)
const Tool = defineAsyncComponent(
  () => import('@/components/basic/atoms/Tool.vue')
)
const TheFooter = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheFooter.vue')
)

export default {
  head() {
    return {
      title: this.title,
      meta: [
        {
          name: 'robots',
          content: containsPrivateInfo() ? NO_INDEX_FOLLOW : this.robots
        },
        { name: 'description', content: this.description }
      ]
    }
  },
  components: {
    TheNoProducts,
    Tool,
    MultiTools,
    TheHeader,
    TheIntroSection,
    AppBanner,
    AlertBanner,
    LazyComponent,
    TheDescriptionSection,
    TheFilter,
    TheFooter,
    UspBar
  },
  data() {
    return {
      adminTool: null,
      bgHeight: ''
    }
  },
  computed: {
    ...mapState(useSearchConfig, [
      'category',
      'getProducts',
      'getConfig',
      'elbwalkerContext'
    ]),
    ...mapState(useManufacturerStore, [
      'manufacturer',
      'calculated',
      'loading',
      'hasMoreInformation'
    ]),
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    visible() {
      return this.manufacturer.status !== 1
    },
    title() {
      return this.manufacturer.meta_title
    },
    description() {
      return (
        this.manufacturer?.meta_description ||
        this.translate('fallback_meta_description')
      )
    },
    robots() {
      if (!this.visible) return NO_INDEX_FOLLOW

      return this.manufacturer.robots > 0 ? NO_INDEX_FOLLOW : INDEX_FOLLOW
    },
    elbContext() {
      const context = this.elbwalkerContext
      context.push(`manufacturer:${this.manufacturer.name}`)
      return context.join(';')
    }
  },
  methods: {
    ...mapActions(useSearchConfig, ['fetchManufacturerProducts']),
    ...mapActions(useManufacturerStore, ['fetchManufacturer']),
    adjustBg() {
      this.bgHeight = `height: ${this.$refs.intro.$el.clientHeight - 30}px; max-height: 600px;`
    },
    setup(id) {
      this.fetchManufacturer(id)
      this.fetchManufacturerProducts(id)
    }
  },
  async created() {
    const { TYPES } = await import('@/constants/AdminTool')
    this.adminTool = TYPES
  },
  beforeRouteEnter({ params: { id } }, _, next) {
    next((vm) => {
      vm.setup(id)
    })
  }
}
</script>
<style scoped>
:deep(.headline-container h2) {
  font-size: var(--xxxl-font-size);
  font-weight: normal;
  display: inline-block;
  padding: 10px 30px;
  position: relative;
  letter-spacing: 1px;
}

@media screen and (min-width: 768px) {
  :deep(.headline-container h2:before) {
    right: 100%;
  }
  :deep(.headline-container h2:after) {
    left: 100%;
  }

  :deep(.headline-container h2:before),
  :deep(.headline-container h2:after) {
    background: rgb(var(--v-theme-beige));
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    width: 20vw;
  }
}

.bg-image {
  object-fit: cover;
  transition: max-height var(--transition-duration-xxxxl);
  min-height: 390px;
  max-height: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#manufacturer-products {
  margin-block: 10px;
  @media screen and (min-width: 768px) {
    margin-block: 50px;
  }
}
</style>
