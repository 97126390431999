<template>
  <div v-show="isVisible" class="overlay"></div>
</template>

<script>
import { useOverlayStore } from '@/stores/overlay'
import { mapState } from 'pinia'

export default {
  computed: {
    ...mapState(useOverlayStore, ['isVisible'])
  }
}
</script>

<style scoped>
.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 10;
  animation: fadeInFromNone var(--transition-duration-l)
    var(--transition-timing-cb-vicampo);
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 0.5;
  }
}
</style>
