import { useCmsVariables } from '@/stores/cmsVariables'
import { useCmsBlocks } from '@/stores/cmsBlocks.js'

export default {
  install(app) {
    const variables = useCmsVariables()
    const blocks = useCmsBlocks()

    app.config.globalProperties.getVariable = (id) => {
      void variables.fetchOne(id)
      return variables.getContent(id)
    }
    app.config.globalProperties.getBlock = (identifier) =>
      blocks.fetchOne(identifier)
  }
}
