<template>
  <div id="content" data-elbglobals="page_type:opc">
    <AlertBanner
      v-if="!productIsVisible"
      :text="translate('alert_message_preview_mode')"
    />
    <TheOpcTemplate id="page-template" />
  </div>
</template>

<script>
import TheOpcTemplate from '@/components/opc/templates/TheOpcTemplate.vue'
import {
  containsPrivateInfo,
  NO_INDEX_FOLLOW
} from '@/services/helpers/MetaHelper.js'
import { useOpcStore } from '@/stores/opc'
import { mapActions, mapState } from 'pinia'
import { defineAsyncComponent } from 'vue'

const AlertBanner = defineAsyncComponent(
  () => import('@/components/basic/atoms/AlertBanner.vue')
)

export default {
  head() {
    return {
      title: this.title,
      meta: [
        {
          name: 'robots',
          content: containsPrivateInfo() ? NO_INDEX_FOLLOW : this.product.robots
        },
        { name: 'description', content: this.description }
      ]
    }
  },
  name: 'TheOpcPage',
  components: {
    AlertBanner,
    TheOpcTemplate
  },
  computed: {
    ...mapState(useOpcStore, ['product', 'updating']),
    productIsVisible() {
      return this.updating || this.product.is_enabled
    },
    title() {
      const manufacturer = this.product.manufacturer_name ?? ''
      const productName = this.product.name ?? ''

      return `${manufacturer} ${productName}`.trim()
    },
    description() {
      return this.product.name
        ? this.product.description
        : this.translate('fallback_meta_description')
    }
  },
  methods: {
    ...mapActions(useOpcStore, ['refreshProduct'])
  },
  beforeRouteEnter(_t, _f, next) {
    next((vm) => {
      vm.refreshProduct()
    })
  }
}
</script>
