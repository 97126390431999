<script setup>
import UspBar from '@/components/basic/atoms/UspBar.vue'
import TheWrapper from '@/components/error/templates/TheErrorTemplate.vue'
import { usePage } from '@/composables/usePage.js'
import { defineAsyncComponent } from 'vue'

const TheHeader = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheHeader.vue')
)
const TheFooter = defineAsyncComponent(
  () => import('@/components/basic/organisms/TheFooter.vue')
)

const { meta, unHead } = usePage()
meta.title = 'fe_app_error_meta'
meta.translateValues = true
unHead()
</script>

<template>
  <div id="content">
    <UspBar />
    <TheHeader />
    <TheWrapper />
    <TheFooter />
  </div>
</template>
