import { defineStore } from 'pinia'

const OPC_CONFIG =
  'embed=bundle_items,expert_reviews,robots,redirect_url,params'

export const useOpcStore = defineStore('opc', {
  state() {
    return {
      product: {
        product_id: null,
        campaign_id: null,
        images: {},
        details: {},
        final_price: 0,
        original_price: 0,
        nutritional_data: {},
        reference_unit_contents: 0,
        description: null,
        manufacturer_name: null,
        name: null,
        awards: [],
        bundle_items: [],
        params: {},
        stocks: [
          {
            name: null,
            is_enabled: true,
            is_in_stock: true,
            shipping_costs: null
          }
        ]
      },
      calculated: {
        isBundle: false,
        quantitySelectList: [],
        images: {
          image: {},
          smallImage: {}
        },
        price: {
          totalSavings: 0,
          final_price: 0
        },
        tags: [],
        packageQty: null
      },
      updating: false,
      pendingOrder: false,
      loading: false,
      selectedQty: 1
    }
  },
  getters: {
    stock: ({ product: { stocks } }) =>
      stocks?.find((stock) => stock.is_enabled && stock.is_in_stock) ?? {},
    params: ({ product: { params } }) => params,
    grandTotal: ({
      selectedQty,
      calculated: {
        price: { finalPrice }
      },
      product: { stocks }
    }) => selectedQty * finalPrice + (stocks?.[0]?.shipping_costs ?? 0)
  },
  actions: {
    async refreshProduct() {
      this.updating = true
      const { ApiController } = await _getModules()

      try {
        const {
          params: { pId, cId }
        } = this.router.currentRoute.value
        const data = await ApiController.fetchProduct(pId, cId, OPC_CONFIG)
        await _checkProduct(data)

        this.product = data
        await this.refreshCalculatedFields()

        _trackView(data)
      } catch (e) {
        this.handleError(e, 'error')
      } finally {
        this.updating = false
      }
    },
    async refreshCalculatedFields() {
      const {
        UtilityHelper,
        createPriceObject,
        SelectOptionFactory,
        PRODUCT_TYPES
      } = await _getModules()

      const images = {
        image: UtilityHelper.prefixImage(this.product.images.image, true),
        smallImage: UtilityHelper.prefixImage(this.product.images.small_image)
      }

      _preloadImages(images)

      const price = await createPriceObject(this.product)
      price.totalSavings =
        this.selectedQty * this.product.original_price -
        this.selectedQty * this.product.final_price
      const tags = this.product.tags ? this.product.tags.split(', ') : []
      const quantitySelectList = SelectOptionFactory.getQtyOptionsForProduct(
        this.product,
        false,
        1
      )
      const packageQty = await _countPackageQty(this.product)
      const isBundle = (this.product?.type ?? '') === PRODUCT_TYPES.BUNDLE

      this.calculated = {
        quantitySelectList,
        price,
        tags,
        images,
        packageQty,
        isBundle
      }
    },
    async scrollToError(firstError, offset) {
      const { $el: element } = firstError
      const { DomHelper } = await _getModules()

      if (!DomHelper.elementInViewport(element, offset)) {
        DomHelper.scrollIntoView(element, offset)
      }
    },
    setLoading(value) {
      this.loading = value
    },
    async updateSelectedQty(qty) {
      this.selectedQty = qty
      await this.refreshCalculatedFields()
    },

    async handleError(error, routeName = 'error') {
      console.error(error)
      this.router.replace({ name: routeName })
    }
  }
})

const _trackView = async ({
  product_id: productId,
  campaign_id: campaignId,
  params = {}
}) => {
  const { TrackingHelper } = await _getModules()
  TrackingHelper.sendUtmParams({ ...params, productId, campaignId })
  TrackingHelper.productView(productId, campaignId)
}

const _countPackageQty = async ({ type, bundle_items: bundleItems }) => {
  const { PRODUCT_TYPES } = await _getModules()

  if (type !== PRODUCT_TYPES.BUNDLE) return 1
  return bundleItems.reduce(
    (quantity, { unit_type: unit, shipping_qty: shippingQty, qty }) =>
      'bottle' === unit ? quantity + shippingQty * qty : quantity,
    0
  )
}

const _checkProduct = async ({ is_opc: isOpc, is_enabled: enabled }) => {
  const { customerStore } = await _getModules()
  await customerStore.waitUntilPending()

  if (!isOpc) {
    this.router.replace(
      this.router.currentRoute.value.fullPath.replace('-opc-', '-pdp-')
    )
    return false
  }

  if (enabled || customerStore.customerIsAdmin) return true

  throw new Error('Disabled product or PDP')
}

const _preloadImages = (images) => {
  const image = new Image()
  const smallImage = new Image()
  image.src = images.image.src
  smallImage.src = images.smallImage.src
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { PRODUCT_TYPES },
      { default: ApiController },
      { default: UtilityHelper },
      { default: MessageHelper },
      { default: DomHelper },
      { default: SelectOptionFactory },
      { createPriceObject },
      { useCustomerStore },
      { default: TrackingHelper }
    ] = await Promise.all([
      import('@/constants/GlobalConstants'),
      import('@/services/ApiController'),
      import('@/services/helpers/UtilityHelper'),
      import('@/services/helpers/MessageHelper'),
      import('@/services/helpers/DomHelper'),
      import('@/modules/select_option/SelectOptionFactory'),
      import('@/services/helpers/ProductHelper'),
      import('@/stores/customer'),
      import('@/services/helpers/TrackingHelper')
    ])

    cache = {
      PRODUCT_TYPES,
      ApiController,
      UtilityHelper,
      MessageHelper,
      DomHelper,
      SelectOptionFactory,
      createPriceObject,
      customerStore: useCustomerStore(),
      TrackingHelper
    }
    return cache
  }
})()
